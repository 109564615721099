import { useState, useEffect, useRef } from "react";
import {
  Box,
  Grid,
  Checkbox,
  IconButton,
  Link,
  FormControl,
  FormLabel,
  OutlinedInput,
  FormHelperText,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  DialogActions,
  Select,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import Icon from "@mdi/react";
import { mdiPencil, mdiDomain, mdiTrashCanOutline } from "@mdi/js";
import styles from "./index.module.scss";
import enterpriseAdminApi from "../../../services/admin/enterprise";
import schoolApi from "../../../services/school/enterpriseInfo";
import ConfirmedDialog from "../../../components/Common/ConfirmedDialog";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../hooks/auth";
import { PermissionLevel } from "../../../constants/PermissionLevel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import Pagination from "../../../components/Common/Pagination"
import SearchBar from "../../../components/Common/SearchBar";

const EnterpriseProfileFormDialog = ({
  open,
  onClose,
  enterpriseData,
  existData,
}) => {
  const { t } = useTranslation();
  const [enterpriseId, setEnterpriseId] = useState("");
  const [enterpriseName, setEnterpriseName] = useState("");
  const [expireTime, setExpireTime] = useState();
  const [enterpriseType, setEnterpriseType] = useState("");
  const [enabled, setEnabled] = useState(true);
  const initHelpText = {
    enterpriseId: "",
    enterpriseName: "",
    expireTime: "",
  };
  const [helperText, setHelperText] = useState(initHelpText);
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const userStore = useSelector((state) => state.user);
  const params = useParams();

  const onExpireTimeChange = (date) => {
    if (date) setExpireTime(new Date(new Date(date).setHours(23, 59, 59)));
    else setExpireTime();
  };

  const verifyInputData = () => {
    let isOK = true;
    let tempHelperText = { ...helperText };

    if (!enterpriseData && existData.some((p) => p.id === enterpriseId)) {
      setHelperText({
        ...helperText,
        enterpriseId: t("ManagementEnterprises.ProfileFormDialog_helpText_id"),
      });
      isOK = false;
    }
    if (enterpriseName === null || enterpriseName.trim() === "") {
      tempHelperText = {
        ...tempHelperText,
        enterpriseName: t("ManagementEnterprises.ProfileFormDialog_helpText_name"),
      };
      isOK = false;
    }
    if (isOK) {
      setHelperText(initHelpText);
    } else {
      setHelperText(tempHelperText);
    }
    return isOK;
  };

  const handleCreateSubmit = async () => {
    if (!verifyInputData()) return;

    let postData = {
      id: enterpriseId,
      name: enterpriseName,
      expireTime: expireTime,
      isEnabled: enabled,
      enterpriseType: enterpriseType
    };
    await enterpriseAdminApi
      .createEnterprise(postData)
      .then((res) => {
        onClose(true);
      })
      .catch((err) => {
        alert(t("ManagementEnterprises.Alert_addEnterprise") + err.response.data.Message);
      });
  };

  const handleEditSubmit = async () => {
    if (!verifyInputData()) return;

    let patchData = {
      name: enterpriseName,
      expireTime: expireTime,
      enterpriseType: enterpriseType,
      isEnabled: enabled,
    };
    await enterpriseAdminApi
      .patchEnterprise(enterpriseId, patchData)
      .then((res) => {
        onClose(true);
      })
      .catch((err) => {
        alert(t("ManagementEnterprises.Alert_editEnterprise") + err.response.data.Message);
      });
  };
  useEffect(() => {
    if (!open) {
      setEnterpriseId("");
      setEnterpriseName("");
      setExpireTime();
      setEnterpriseType("");
      setEnabled(true);
    } else if (enterpriseData) {
      setEnterpriseId(enterpriseData.id);
      setEnterpriseName(enterpriseData.name);
      setEnterpriseType(enterpriseData.type);
      if (enterpriseData.expireTime)
        setExpireTime(new Date(enterpriseData.expireTime));
      setEnabled(enterpriseData.status === "Enabled");
    }
  }, [open, enterpriseData]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => onClose(false)}
        scroll="paper"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {enterpriseData
            ? t("ManagementEnterprises.ProfileFormDialog_title_edit")
            : t("ManagementEnterprises.ProfileFormDialog_title_add")}
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <FormControl>
                <FormLabel>{t("ManagementEnterprises.ProfileFormDialog_label_id")}</FormLabel>
                <OutlinedInput
                  margin="dense"
                  type="text"
                  fullWidth
                  value={enterpriseId}
                  disabled={!!enterpriseData}
                  placeholder={t("ManagementEnterprises.ProfileFormDialog_placeholder_id")}
                  size="small"
                  onChange={(e) => setEnterpriseId(e.target.value)}
                  variant="outlined"
                />
                <FormHelperText error={helperText.enterpriseId != ""}>
                  {helperText.enterpriseId}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl>
                <FormLabel>{t("ManagementEnterprises.ProfileFormDialog_label_name")}</FormLabel>
                <OutlinedInput
                  margin="dense"
                  type="text"
                  fullWidth
                  value={enterpriseName}
                  placeholder={t("ManagementEnterprises.ProfileFormDialog_placeholder_name")}
                  size="small"
                  onChange={(e) => setEnterpriseName(e.target.value)}
                  variant="outlined"
                />
                <FormHelperText error={helperText.enterpriseName != ""}>
                  {helperText.enterpriseName}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl sx={{ minWidth: 240 }} size="small">
                <FormLabel>{t("ManagementEnterprises.ProfileFormDialog_enterpriseType")}</FormLabel>
                <Select
                  value={enterpriseType}
                  variant="outlined"
                  onChange={(e) => setEnterpriseType(e.target.value)}
                >
                  <MenuItem value="E">{t("ManagementEnterprises.ProfileFormDialog_Select_enterprise")}</MenuItem>
                  <MenuItem value="S">{t("ManagementEnterprises.ProfileFormDialog_Select_school")}</MenuItem>
                  <FormHelperText error={helperText.enterpriseName != ""}>
                    {helperText.enterpriseName}
                  </FormHelperText>
                </Select>
              </FormControl>
            </Grid>
            <Grid item container direction="row">
              <FormControl>
                <FormLabel>{t("ManagementEnterprises.ProfileFormDialog_label_validity")}</FormLabel>
                <DatePicker
                  className={styles.datepicker}
                  selected={expireTime}
                  popperPlacement="right-end"
                  onChange={(date) => onExpireTimeChange(date)}
                  placeholderText={t("ManagementEnterprises.ProfileFormDialog_placeholder_validity")}
                  dateFormat="yyyy/MM/dd"
                  customInput={
                    <OutlinedInput size="small" sx={{ m: 1, minWidth: 120 }} />
                  }
                  withPortal={windowSize.current[0] < 600}
                ></DatePicker>
                <FormHelperText>
                  {t("ManagementEnterprises.ProfileFormDialog_helpText_date")}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={enabled}
                      onChange={(e) => setEnabled(e.target.checked)}
                    />
                  }
                  label={t("ManagementEnterprises.Checkbox_label_enable")}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(false)}>
            {t("ManagementEnterprises.ProfileFormDialog_button_cancel")}
          </Button>
          {!enterpriseData && (
            <Button variant="contained" onClick={handleCreateSubmit}>
              {t("ManagementEnterprises.ProfileFormDialog_button_createConfirm")}
            </Button>
          )}
          {enterpriseData && (
            <Button variant="contained" onClick={handleEditSubmit}>
              {t("ManagementEnterprises.ProfileFormDialog_button_editConfirm")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

const ManagementEnterprises = () => {
  const { t } = useTranslation();
  const profileDialogInit = {
    open: false,
    enterpriseData: null,
  };
  const [profileDialog, setProfileDialog] = useState(profileDialogInit);
  const [enterpriseList, setEnterpriseList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [statusConfirmDialog, setStatusConfirmDialog] = useState({
    open: false,
    target: null,
  });
  const params = useParams();
  const navigate = useNavigate();
  const authStore = useSelector((state) => state.auth);
  const userStore = useSelector((state) => state.user);
  // 目前所在頁數
  const [currentPage, setCurrentPage] = useState(1);
  // 每頁顯示項目數量
  const itmesPerPage = 20;
  // 總共需要的頁數 
  const totalPages = Math.ceil(enterpriseList.length / itmesPerPage);
  // 計算該頁面最後 item 為第幾個。
  const indexOfLastItem = currentPage * itmesPerPage;
  // 計算該頁面開始 item 為第幾個。
  const indexOfFirstItem = indexOfLastItem - itmesPerPage;
  // 提取該頁面要顯示的資料
  const currentItems = enterpriseList.slice(indexOfFirstItem, indexOfLastItem);

  const handleSelected = (event, targetItem) => {
    let newList = enterpriseList.map((item) => {
      if (item.id === targetItem.id) {
        return { ...item, checked: event.target.checked };
      }
      return item;
    });
    setEnterpriseList(newList);
  };

  const onStatusCancleClick = () => {
    setStatusConfirmDialog({
      ...statusConfirmDialog,
      open: false,
      target: null,
    });
  };

  const onStatusConfirmedClick = async () => {
    let targetStatus =
      statusConfirmDialog.target.status === "Enabled" ? "Disabled" : "Enabled";
    await enterpriseAdminApi
      .changeStatus(statusConfirmDialog.target.id, targetStatus)
      .then((res) => {
        fetchEnterpirses();
        setStatusConfirmDialog({
          ...statusConfirmDialog,
          open: false,
          target: null,
        });
      });
  };

  const onToggleStatusClick = (item) => {
    setStatusConfirmDialog({
      ...statusConfirmDialog,
      open: true,
      target: item,
    });
  };


  const fetchEnterpirses = async () => {
    if (
      authStore.roles.includes("SuperAdmin") &&
      authStore.enterpriseId === params.enterpriseId
    ) {
      await enterpriseAdminApi.getEnterprises(searchText).then((res) => {
        setEnterpriseList(
          res.data.map((item) => ({
            ...item,
            checked: false,
          }))
        );
      });
    } else {
      await schoolApi
        .getChilds(params.enterpriseId, searchText)
        .then((res) => {
          setEnterpriseList(
            res.data.map((item) => ({
              ...item,
              checked: false,
            }))
          );
        });
    }
  };
  const displayStatus = (item) => {
    switch (item.status) {
      case "Disabled":
        return (
          <span style={{ color: "#ff9900" }}>
            {t("ManagementEnterprises.Status_return_deactivated")}
          </span>
        );
      case "Enabled":
        if (item.expireTime) {
          if (Date.now() > new Date(item.expireTime))
            return (
              <span style={{ color: "#d50000" }}>
                {t("ManagementEnterprises.Status_return_expired")}
              </span>
            );
        }
        return (
          <span style={{ color: "#62AF00" }}>
            {t("ManagementEnterprises.Status_return_activated")}
          </span>
        );
    }
  };

  const onProfileDialogClose = (refresh) => {
    if (refresh) fetchEnterpirses();
    setProfileDialog(profileDialogInit);
  };


  const gotoEnterpirseUrl = (enterprise) => {
    console.log(enterprise);

    switch (enterprise.type) {
      case "S":
        return `/school/${enterprise.id}/enterpriseInfo`;
      case "E":
        return `/enterprise/${enterprise.id}/information/overview`;
    }
  };

  // 從 <PaginationBar> 取得當前所在頁數
  const handleCurrentPage = (page) => {
    setCurrentPage(page)
  }

  const handleInputText = (data) => {
    setSearchText(data);
  };

  useEffect(() => {
    if (!authStore.permissionLevel) return;
    fetchEnterpirses();
  }, [authStore.permissionLevel, searchText]);

  return (
    <>
      <Box>
        <Box>
          <div className={styles.title}>{t("ManagementEnterprises.Title")}</div>
          <div className={styles.description}>
            {t("ManagementEnterprises.Description")}
          </div>
        </Box>
        {/* <Stack direction="row" spacing={1}>

            </Stack> */}
        <Box className={styles.main_content}>
          <Grid item className={styles.card} container>
            <div className={styles.card_title}>
              {t("ManagementEnterprises.Card_title")}
            </div>
            <div className={styles.card_subtitle}>
              {t("ManagementEnterprises.Card_subtitle")}
            </div>

            <Grid
              container
              item
              alignItems="start"
              direction="row"
              justifyContent="space-between"
              gap={1}
            >
              {authStore.permissionLevel >= PermissionLevel.SuperAdmin && (
                <>
                  <SearchBar InputTextValue={handleInputText} />
                  <Button
                    variant="contained"
                    onClick={() => setProfileDialog({ open: true })}
                  >
                    {t("ManagementEnterprises.Button_addEnterprise")}
                  </Button>
                </>
              )}
            </Grid>

            <div>
              <table className={styles.table} cellSpacing="0">
                <thead>
                  <tr>
                    <th width="40"></th>
                    <th>{t("ManagementEnterprises.Table_name")}</th>
                    <th>{t("ManagementEnterprises.Table_id")}</th>
                    <th>{t("ManagementEnterprises.Table_validity")}</th>
                    <th>{t("ManagementEnterprises.Table_limitation")}</th>
                    <th>{t("ManagementEnterprises.Table_status")}</th>
                    <th>{t("ManagementEnterprises.Table_parent")}</th>
                    {authStore.permissionLevel >=
                      PermissionLevel.SuperAdmin && (
                        <th style={{ paddingLeft: "10px" }}>
                          {t("ManagementEnterprises.Table_function")}
                        </th>
                      )}
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td></td>
                        <td data-label={`${t("ManagementEnterprises.Table_name")}: `}>
                          <Link href={`${gotoEnterpirseUrl(item)} `}>
                            {item.name}
                          </Link>
                        </td>
                        <td data-label={`${t("ManagementEnterprises.Table_id")}: `}>
                          {item.id}
                        </td>
                        <td data-label={`${t("ManagementEnterprises.Table_validity")}: `}>
                          {!item.expireTime
                            ? "-"
                            : new Date(item.expireTime).toLocaleDateString()}
                        </td>
                        <td data-lable={`${t("ManagementEnterprises.Table_limitation")}: `}>
                          {!item.userLimit ? "-" : item.userLimit}
                        </td>
                        <td data-label={`${t("ManagementEnterprises.Table_status")}: `}>
                          <div
                            className={styles.btn_status}
                            onClick={() => {
                              onToggleStatusClick(item);
                            }}
                          >
                            {displayStatus(item)}
                          </div>
                        </td>
                        <td data-label={`${t("ManagementEnterprises.Table_parent")}: `}>
                          {item.parentName ?? "-"}
                        </td>
                        {
                          authStore.permissionLevel >=
                          PermissionLevel.SuperAdmin && (
                            <td>
                              <IconButton
                                className={styles.btn_edit}
                                onClick={() =>
                                  setProfileDialog({
                                    open: true,
                                    enterpriseData: item,
                                  })
                                }
                              >
                                <Icon path={mdiPencil} size={1} />
                              </IconButton>
                            </td>
                          )
                        }
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Grid>
        </Box>
        <Pagination inputText={searchText} totalPages={totalPages} currentPage={handleCurrentPage} dataLength={enterpriseList.length} />
      </Box >
      <EnterpriseProfileFormDialog
        open={profileDialog.open}
        enterpriseData={profileDialog.enterpriseData}
        onClose={onProfileDialogClose}
        existData={enterpriseList}
      />

      <ConfirmedDialog
        open={statusConfirmDialog.open}
        title={`${statusConfirmDialog.target?.status === "Enabled"
          ? t("ManagementEnterprises.ConfirmedDialog_title_deactivate")
          : t("ManagementEnterprises.ConfirmedDialog_title_activate")
          }${t("ManagementEnterprises.ConfirmedDialog_text_enterprise")} `}
        onCancleClick={onStatusCancleClick}
        onConfirmedClcik={onStatusConfirmedClick}
      >
        <div>
          {t("ManagementEnterprises.ConfirmedDialog_text_status",
            {
              var: statusConfirmDialog.target?.status === "Enabled"
                ? t("ManagementEnterprises.ConfirmedDialog_text_deactivate")
                : t("ManagementEnterprises.ConfirmedDialog_text_activate")
            })}
          <strong>{statusConfirmDialog.target?.name}</strong>？
        </div>
      </ConfirmedDialog>
    </>
  );
};

export default ManagementEnterprises;
