import { Routes, Route } from 'react-router-dom';
import EnterpriseMainPage from '../pages/Enterprise/EnterpriseMainPage';
import OverviewPage from '../pages/Enterprise/OverviewPage';
import { use, useEffect } from 'react';
import ManagementCourses from '../pages/Enterprise/ManagementCourses';
import ManagementUsers from '../pages/Enterprise/ManagementUsers';
import ImportTask from '../pages/School/ImportTask';
import ReportPage from '../pages/Enterprise/ReportPage';

const EnterpriseRoute = () => {
    return (
        <Routes>
            <Route path="/" element={<EnterpriseMainPage />}>
                <Route path="information/overview" element={<OverviewPage />} />
                <Route path="information/courses" element={<ManagementCourses />} />
                <Route path="information/users" element={<ManagementUsers />} />
                <Route path="information/import" element={<ImportTask />} />
                <Route path="contents/packages" element={<OverviewPage />} />
                <Route path="reports/export" element={<ReportPage />} />
                <Route path="reports/mastery" element={<OverviewPage />} />
                <Route path="reports/learningTime" element={<OverviewPage />} />
                <Route path="reports/learningStatus" element={<OverviewPage />} />
                <Route path="reports/group" element={<OverviewPage />} />
                <Route path="notification/send" element={<OverviewPage />} />
                <Route path="notification/templates" element={<OverviewPage />} />
                <Route path="notification/records" element={<OverviewPage />} />
            </Route>
        </Routes>
    );
};

export default EnterpriseRoute;