import { fetchData, fetchDataWithRetry } from "../api";

const getExamPackages = async (enterpriseId, searchText = null) => {
    const path = `/School/${enterpriseId}/ExamPackages`;
    const config = {
        query: {
            searchText: searchText
        }
    };
    return fetchDataWithRetry(path, config);
}

const addExamPackages = async (enterpriseId, examPackages) => {
    const postData = {
        examPackages: examPackages
    };
    const path = `/School/${enterpriseId}/ExamPackages`;
    const config = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
    };
    return fetchDataWithRetry(path, config);
}

const patchExamPackage = async (enterpriseId, examPackage) => {
    const path = `/School/${enterpriseId}/ExamPackages`;
    const config = {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(examPackage),
    };
    return fetchDataWithRetry(path, config);
}

const removeExamPackage = async (enterpriseId, examPackageIds) => {
    let deleteData = {
        examPackageIds: examPackageIds
    };
    const path = `/School/${enterpriseId}/ExamPackages`;
    const config = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(deleteData),
    };
    return fetchDataWithRetry(path, config);
}
const getCourses = async (enterpriseId, examPackageId) => {

    return fetchDataWithRetry(`/School/${enterpriseId}/ExamPackages/${examPackageId}/Courses`);
}

export default {
    getExamPackages,
    addExamPackages,
    patchExamPackage,
    removeExamPackage,
    getCourses
}
