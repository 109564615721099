import { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Button,
  Container,
  Typography,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  SvgIcon,
} from "@mui/material";
import styles from "./index.module.scss";
import logo from "../../../assets/icon_footer_logo_3x.png";
import { useAuth } from "../../../hooks/auth";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMediaQuery, useTheme } from '@mui/material';
import Icon from "@mdi/react";
import { mdiMenu } from "@mdi/js";



// const loggedin = [t("Topbar.LogOut")];

const Topbar = (props) => {
  const { t } = useTranslation();
  const auth = useAuth();
  // const params = useParams();
  const navigate = useNavigate();
  // const [searchText, setSearchText] = useState("");
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      {isSmDown && (
        <AppBar
          position="static"
          sx={{ backgroundColor: 'rgba(255, 255, 255, 0)', boxShadow: 'none', zIndex: 1201 }}
        >
          <Container
            maxWidth={false}
            sx={{ backgroundColor: 'rgba(255, 255, 255, 0)', zIndex: 1201 }}
          >
            <IconButton
              className={styles.btn_menu_icon}
              onClick={props.handleDrawerOpen}
              sx={{ display: { xs: "block", sm: "none" } }}
            >
              <Icon className={styles.menu_icon} path={mdiMenu}
                color={props.drawerOpen ? 'white' : 'black'} />
            </IconButton>

          </Container>
        </AppBar>
      )}
    </>
  );
};
export default Topbar;
