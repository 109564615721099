import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const initialState = {
    id: "",
    info: {},
    onLineUserAmount: 0,
    startDate: null,
    endDate: null,
}

const enterpriseSlice = createSlice({
    name: "enterprise",
    initialState,
    reducers: {
        setInfo(state, action) {
            state.id = action.payload.id;
            state.info = action.payload.info;
            state.onLineUserAmount = action.payload.info.onLineUserAmount;
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
        },
        reset: () => initialState,
    }
});

export const {
    setInfo,
    reset
} = enterpriseSlice.actions;

export default enterpriseSlice.reducer

