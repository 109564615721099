import { fetchData, fetchDataWithRetry } from "../api";

const getSummary = async (
  enterpriseId,
  courseId = null,
  examPackageId = null,
  userId = null
) => {
  const path = `/School/${enterpriseId}/Records/Summary`;
  const config = {
    query: {
      examPackageId: examPackageId,
      userId: userId,
      courseId: courseId,
    },
  };
  return fetchDataWithRetry(path, config);
};

const getDailySummary = async (
  enterpriseId,
  beginDate,
  endDate,
  gap,
  courseId,
  examPackageId
) => {
  const path = `/School/${enterpriseId}/Records/DailySummary`;
  const config = {
    query: {
      examPackageId: examPackageId,
      courseId: courseId,
      gap: gap,
      beginDate: beginDate,
      endDate: endDate,
    },
  };
  return fetchDataWithRetry(path, config);
};

const getHourlyUsageAnalytics = async (enterpriseId) => {
  const path = `/School/${enterpriseId}/Records/HourlyUsageAnalytics`;
  const config = {
    query: {
      enterpriseId: enterpriseId,
    },
  };
  return fetchDataWithRetry(path, config);
};

const getKnowledgeSkyMap = async (
  enterpriseId,
  examPackageId,
  type,
  userId
) => {
  const path = `/School/${enterpriseId}/Records/KnowledgeSkyMap`;
  const config = {
    query: {
      examPackageId: examPackageId,
      type: type,
      userId: userId,
    },
  };
  return fetchDataWithRetry(path, config);
};

const getKnowledgeMastery = async (
  enterpriseId,
  examPackageId,
  type,
  userId
) => {
  const path = `/School/${enterpriseId}/Records/KnowledgeMastery`;
  const config = {
    query: {
      examPackageId: examPackageId,
      type: type,
      userId: userId,
    },
  };
  return fetchDataWithRetry(path, config);
};

const getDailyReport = async (
  enterpriseId,
  courseId,
  examPackageId,
  beginDate,
  endDate
) => {
  const path = `/School/${enterpriseId}/Records/DailyReport`;
  const config = {
    query: {
      examPackageId: examPackageId,
      courseId: courseId,
      beginDate: beginDate,
      endDate: endDate,
    },
  };
  return fetchDataWithRetry(path, config);
};

const getExamPackageReport = async (enterpriseId, courseId, examPackageId) => {
  const path = `/School/${enterpriseId}/Records/ExamPackageReport`;
  const config = {
    query: {
      examPackageId: examPackageId,
      courseId: courseId,
    },
  };
  return fetchDataWithRetry(path, config);
};
const getKnowledgePointReport = async (
  enterpriseId,
  examPackageId,
  courseId = null
) => {
  const path = `/School/${enterpriseId}/Records/KnowledgePointReport`;
  const config = {
    query: {
      examPackageId: examPackageId,
      courseId: courseId,
    },
  };
  return fetchDataWithRetry(path, config);
};

const getPreTestScoreReport = async (
  enterpriseId,
  examPackageId,
  courseId = null
) => {
  const path = `/School/${enterpriseId}/Records/PreTestScoreReport`;
  const config = {
    query: {
      examPackageId: examPackageId,
      courseId: courseId,
    },
  };
  return fetchDataWithRetry(path, config);
};

const getRanking = async (
  enterpriseId,
  courseId = null,
  examPackageId = null
) => {
  const path = `/School/${enterpriseId}/Records/Ranking`;
  const config = {
    query: {
      examPackageId: examPackageId,
      courseId: courseId,
    },
  };
  return fetchDataWithRetry(path, config);
};


const getUserSprint = async (
  enterpriseId,
  userId = null,
  examPackageId = null
) => {
  const path = `/School/${enterpriseId}/Records/UserSprint`;
  const config = {
    query: {
      examPackageId: examPackageId,
      UserId: userId,
    },
  };
  return fetchDataWithRetry(path, config);
};

const getUserKnowledgePoint = async (
  enterpriseId,
  userId = null,
  examPackageId = null
) => {
  const path = `/School/${enterpriseId}/Records/UserKnowledgePoint`;
  const config = {
    query: {
      examPackageId: examPackageId,
      UserId: userId,
    },
  };
  return fetchDataWithRetry(path, config);
};

export default {
  getSummary,
  getDailySummary,
  getHourlyUsageAnalytics,
  getKnowledgeSkyMap,
  getKnowledgeMastery,
  getDailyReport,
  getExamPackageReport,
  getKnowledgePointReport,
  getPreTestScoreReport,
  getRanking,
  getUserSprint,
  getUserKnowledgePoint,
};
