import React, { useEffect, useState, useRef } from "react";
import styles from "./index.module.scss";
import userApi from "../../../services/school/users";
import {
  Button,
  FormLabel,
  Dialog,
  TextField,
  FormControl,
  DialogActions,
  DialogContent,
  Autocomplete,
  FormGroup,
  FormControlLabel,
  Checkbox,
  DialogTitle,
  OutlinedInput,
  InputAdornment,
  Box,
  FormHelperText,
  Grid,
} from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { useAuth } from "../../../hooks/auth";
import { PermissionLevel } from "../../../constants/PermissionLevel";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ConfirmedDialog from "../ConfirmedDialog";
import { useTranslation } from "react-i18next";

const UserProfileFormDialog = ({
  open,
  onClose,
  userData,
  suggestUsers,
  disableRole,
  disableCreate,
  allowConfilct,
}) => {
  const { t } = useTranslation();
  const [mode, setMode] = useState("check");
  const [enterpriseUserId, setEnterpriseUserId] = useState("");
  const [fullName, setFullName] = useState("");
  const [password, setPassword] = useState("");
  const [enablePassword, setEnablePassword] = useState(false);
  const [roles, setRoles] = useState(["Student"]);
  const [expireTime, setExpireTime] = useState();
  const [rescueConfirmDialog, setRescueConfirmDialog] = useState({
    open: false,
    userId: "",
  });
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const initHelpText = {
    enterpriseUserId: "",
    password: "",
    fullname: "",
    roles: "",
  };
  const [helperText, setHelperText] = useState(initHelpText);
  const authStore = useSelector((state) => state.auth);
  const param = useParams();
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => `${option.fullName}(${option.enterpriseUserId})`,
  });

  useEffect(() => {
    if (!open) {
      setMode("check");
      setEnterpriseUserId("");
      setFullName("");
      setPassword("");
      setRoles(["Student"]);
      setExpireTime();
      setEnablePassword(false);
      setHelperText(initHelpText);
      return;
    }
    if (userData) {
      setMode("edit");
      setEnterpriseUserId(userData.enterpriseUserId);
      setFullName(userData?.fullName ?? "");
      setRoles(userData.roles);
      if (userData.expireTime) setExpireTime(new Date(userData.expireTime));
    }
  }, [open]);
  useEffect(() => {
    if (mode === "create") setEnablePassword(true);
  }, [mode]);

  useEffect(() => {
    if (password === null || password.trim() === "") return;
    if (password.length < 8)
      setHelperText({
        ...helperText,
        password: t("UserProfileFormDialog.HelperText_passwordLength"),
      });
    else setHelperText({ ...helperText, password: "" });
  }, [password]);

  const verifyInputData = () => {
    let isOK = true;
    let tempHelperText = { ...helperText };
    if (mode === "create" && (password === null || password.trim() === "")) {
      tempHelperText = {
        ...tempHelperText,
        password: t("UserProfileFormDialog.HelperText_password"),
      };
      isOK = false;
    }
    if (!(password === null || password.trim() === "") && password.length < 8) {
      isOK = false;
    }
    if (fullName === null || fullName.trim() == "") {
      tempHelperText = {
        ...tempHelperText,
        fullname: t("UserProfileFormDialog.HelperText_Fullname"),
      };
      isOK = false;
    }
    if (!roles || roles.length <= 0) {
      tempHelperText = {
        ...tempHelperText,
        roles: t("UserProfileFormDialog.HelperText_role"),
      };
      isOK = false;
    }
    if (isOK) {
      setHelperText(initHelpText);
    } else {
      setHelperText(tempHelperText);
    }

    console.log(tempHelperText);
    return isOK;
  };
  const handleEditSubmit = async () => {
    if (!verifyInputData()) return;

    const patchData = {
      fullName: fullName,
      password: password,
      expireTime: expireTime,
      roles: roles,
    };
    await userApi
      .patchUser(param.enterpriseId, userData.id, patchData)
      .then((res) => {
        onClose(userData);
      })
      .catch((err) => {
        alert(t("UserProfileFormDialog.EditSubmit_alert") + err.response.data.Message);
      });
  };

  const getUserInfo = async (enterpriseUserId) => {
    let userId;
    await userApi
      .checkAvailable(param.enterpriseId, enterpriseUserId)
      .then((res) => {
        userId = res.data;
      });
    return userId;
  };

  const handleCreateSubmit = async () => {
    if (!verifyInputData()) return;

    let emailRule =
      /^\w+((-\w+)|(\.\w+)|(\+\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;
    let email = "";
    if (enterpriseUserId.search(emailRule) != -1) {
      email = enterpriseUserId;
    }
    const postData = {
      enterpriseUserId: enterpriseUserId,
      fullName: fullName,
      password: password,
      roles: roles,
      email: email,
      expireTime: expireTime,
    };
    await userApi
      .createUser(param.enterpriseId, postData)
      .then(async (res) => {
        let userInfo = await getUserInfo(postData.enterpriseUserId);
        onClose(userInfo);
      })
      .catch((err) => {
        alert(t("UserProfileFormDialog.CreateSubmit_alert") + err.response.data.Message);
      });
  };

  const handleCheckSubmit = async () => {
    if (enterpriseUserId == null || enterpriseUserId.trim().length == 0) return;
    let userInfo = await getUserInfo(enterpriseUserId);
    if (userInfo && userInfo.isExist) {
      if (userInfo.userStatus === "Deleted") {
        setRescueConfirmDialog({ open: true, userId: userInfo.id });
        setHelperText({
          ...helperText,
          enterpriseUserId: t("UserProfileFormDialog.HelperText_deleted"),
        });
      } else if (allowConfilct) {
        onClose(userInfo);
      } else {
        setHelperText({
          ...helperText,
          enterpriseUserId: t("UserProfileFormDialog.HelperText_exists"),
        });
      }
    } else if (disableCreate) {
      setHelperText({
        ...helperText,
        enterpriseUserId: t("UserProfileFormDialog.HelperText_notFound"),
      });
    } else {
      setMode("create");
      setHelperText({
        ...helperText,
        enterpriseUserId: t("UserProfileFormDialog.HelperText_notCreated"),
      });
    }
  };

  const handlerRolesChange = async (event, roleName) => {
    if (event.target.checked) {
      setRoles([...roles, roleName]);
    } else {
      setRoles(roles.filter((p) => p !== roleName));
    }
  };

  const onExpireTimeChange = (date) => {
    if (date) setExpireTime(new Date(new Date(date).setHours(23, 59, 59)));
    else setExpireTime();
  };

  const onRescueCancleClick = () => {
    setRescueConfirmDialog({ open: false });
    onClose();
  };
  const onRescueConfirmedClcik = async () => {
    await userApi
      .changeStatus(param.enterpriseId, rescueConfirmDialog.userId, "Enabled")
      .then(async (res) => {
        let userInfo = await getUserInfo(enterpriseUserId);
        onClose(userInfo);
      })
      .catch((err) => {
        onClose();
      })
      .finally(() => {
        setRescueConfirmDialog({ open: false });
      });
  };

  return (
    <div>
      <Dialog open={open} onClose={() => onClose()} fullWidth maxWidth="sm">
        <DialogTitle>
          {mode === "edit"
            ? t("UserProfileFormDialog.Edit_dialog_title")
            : t("UserProfileFormDialog.Add_dialog_title")}
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <FormControl fullWidth>
                <FormLabel>{t("UserProfileFormDialog.Edit_dialog_label_account")}</FormLabel>
                <Autocomplete
                  freeSolo
                  disableClearable
                  options={suggestUsers || []}
                  getOptionLabel={(option) => option?.enterpriseUserId || ""}
                  disabled={mode == "edit"}
                  margin="dense"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={{ enterpriseUserId }}
                  onChange={(e, option) =>
                    setEnterpriseUserId(option?.enterpriseUserId)
                  }
                  inputValue={enterpriseUserId}
                  onInputChange={(event, inputValue) => {
                    setEnterpriseUserId(inputValue);
                  }}
                  filterOptions={filterOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t("UserProfileFormDialog.Add_dialog_placeholder")}
                      size="small"
                      hiddenLabel
                    />
                  )}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      {option.fullName} ({option.enterpriseUserId})
                    </Box>
                  )}
                />
                <FormHelperText
                  classes={{
                    filled: styles.success_helper,
                  }}
                  error={mode === "check" && helperText.enterpriseUserId != ""}
                  filled={
                    mode !== "check" && helperText.enterpriseUserId !== ""
                  }
                >
                  {helperText.enterpriseUserId}
                </FormHelperText>
              </FormControl>
            </Grid>
            {["edit", "create"].includes(mode) && (
              <>
                <Grid item>
                  {!enablePassword && (
                    <Button
                      variant="outlined"
                      className={styles.btn_enable_password}
                      onClick={() => setEnablePassword(true)}
                    >
                      {t("UserProfileFormDialog.Edit_dialog_button_resetPassword")}
                    </Button>
                  )}
                  {enablePassword && (
                    <FormControl>
                      <FormLabel>
                        {t("UserProfileFormDialog.Edit_dialog_label_password")}
                      </FormLabel>
                      <OutlinedInput
                        placeholder={t("UserProfileFormDialog.Edit_dialog_placeholder_password")}
                        type="text"
                        value={password}
                        size="small"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <FormHelperText error={helperText.password != ""}>
                        {helperText.password}
                      </FormHelperText>
                    </FormControl>
                  )}
                </Grid>
                <Grid item>
                  <FormControl>
                    <FormLabel>
                      {t("UserProfileFormDialog.Edit_dialog_label_name")}
                    </FormLabel>
                    <OutlinedInput
                      placeholder={t("UserProfileFormDialog.Edit_dialog_placeholder_name")}
                      type="text"
                      value={fullName}
                      size="small"
                      onChange={(e) => setFullName(e.target.value)}
                    />
                    <FormHelperText error={helperText.fullname != ""}>
                      {helperText.fullname}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                {!disableRole &&
                  authStore.permissionLevel >=
                  PermissionLevel.EnterpriseAdmin && (
                    <>
                      <Grid item>
                        <FormControl>
                          <FormLabel>
                            {" "}
                            {t("UserProfileFormDialog.Edit_dialog_label_role")}
                          </FormLabel>
                          <FormGroup row>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={roles.includes("Student")}
                                  onChange={(e) =>
                                    handlerRolesChange(e, "Student")
                                  }
                                />
                              }
                              label={t("UserProfileFormDialog.Edit_dialog_checkbox_student")}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={roles.includes("Teacher")}
                                  onChange={(e) =>
                                    handlerRolesChange(e, "Teacher")
                                  }
                                />
                              }
                              label={t("UserProfileFormDialog.Edit_dialog_checkbox_teacher")}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={roles.includes("EnterpriseAdmin")}
                                  onChange={(e) =>
                                    handlerRolesChange(e, "EnterpriseAdmin")
                                  }
                                />
                              }
                              label={t("UserProfileFormDialog.Edit_dialog_checkbox_administrator")}
                            />
                          </FormGroup>
                          <FormHelperText error={helperText.roles != ""}>
                            {helperText.roles}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <FormControl>
                          <FormLabel>
                            {t("UserProfileFormDialog.Edit_dialog_label_expiry")}
                          </FormLabel>
                          <DatePicker
                            className={styles.expireTime}
                            selected={expireTime}
                            popperPlacement="top-end"
                            onChange={(date) => onExpireTimeChange(date)}
                            placeholderText={t(
                              "UserProfileFormDialog.Edit_dialog_placeholder_date"
                            )}
                            dateFormat="yyyy/MM/dd"
                            customInput={
                              <OutlinedInput
                                size="small"
                                sx={{ m: 1, minWidth: 120 }}
                              />
                            }
                            withPortal={windowSize.current[0] < 600}
                          ></DatePicker>
                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                    </>
                  )}
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => onClose()}>
            {t("UserProfileFormDialog.Edit_dialog_button_cancel")}
          </Button>
          {mode === "check" && (
            <Button variant="contained" onClick={handleCheckSubmit}>
              {t("UserProfileFormDialog.Edit_dialog_button_confirm_check")}
            </Button>
          )}
          {mode === "create" && (
            <Button variant="contained" onClick={handleCreateSubmit}>
              {t("UserProfileFormDialog.Edit_dialog_button_confirm_create")}
            </Button>
          )}
          {mode === "edit" && (
            <Button variant="contained" onClick={handleEditSubmit}>
              {t("UserProfileFormDialog.Edit_dialog_button_confirm_edit")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <ConfirmedDialog
        open={rescueConfirmDialog.open}
        title={t("UserProfileFormDialog.Deleted_dialog_title")}
        onCancleClick={onRescueCancleClick}
        onConfirmedClcik={onRescueConfirmedClcik}
      >
        <>
          {enterpriseUserId}
          <br />
          <span className={styles.rescue_msg}>
            {t("UserProfileFormDialog.Deleted_dialog_textMsg")}
          </span>
          <br />
          <br />
          <br />
        </>
      </ConfirmedDialog>
    </div>
  );
};

export default UserProfileFormDialog;
