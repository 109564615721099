import React, { useState, useEffect } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';
import Icon from "@mdi/react";
import { mdiChevronDown, mdiChevronRight, mdiCircleSmall } from '@mdi/js';
import Box from "@mui/material/Box";
import styles from "./index.module.scss";
import classnames from "classnames";

const TreeItem = ({ label, children, hasChildren, onClick, level, isOpen, isActive, icon }) => {
  return (
    <div className={styles.treeItem}>
      <div
        className={classnames(styles.treeLabel, { [styles.level1]: level === 1, [styles.level2]: level > 1, [styles.open]: isOpen && level === 1, [styles.active]: isActive })}
        onClick={onClick}
      >
        <span className={styles.treeLabelContent}>
          {level === 1 && icon && <Icon path={icon} size={0.6} className={styles.treeIcon} />}
          {level > 1 && <Icon path={mdiCircleSmall} size={0.8} className={styles.treeDot} />}
          {label}
        </span>

        <span>
          {hasChildren && (
            isOpen ? (
              <Icon path={mdiChevronDown} size={0.8} />
            ) : (
              <Icon path={mdiChevronRight} size={0.8} />
            )
          )}
        </span>
      </div>
      {isOpen && <div className={styles.treeChildren}>{children}</div>}
    </div>
  );
};



const Sidebar = ({ navs, onClose }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openParentItemId, setOpenParentItemId] = useState(null);



  useEffect(() => {
    const pathSegments = location.pathname.split('/').filter(Boolean);
    if (pathSegments.length >= 3) {
      const parentItemId = pathSegments[2];
      setOpenParentItemId(parentItemId);
    }
  }, [location.pathname, navs]);



  const handleToggle = (itemId) => {
    setOpenParentItemId(openParentItemId === itemId ? null : itemId);
  };

  const handleOnClick = (item) => {
    if (item.to) {
      navigate(item.to);
    }
    else {
      handleToggle(item.id);
    }
  }


  const renderTreeItems = (items, level = 1) => {
    return items.map((item) => {
      const isActive = location.pathname.includes(item.to);
      const isOpen = openParentItemId === item.id;
      return (
        <div className={classnames({ [styles.treeItemWrapper]: level === 1, [styles.open]: isOpen && level === 1 })} key={item.id}>
          <TreeItem
            key={item.id}
            label={item.text}
            hasChildren={!!item.items}
            onClick={() => handleOnClick(item)}
            level={level}
            isOpen={isOpen}
            isActive={isActive}
            icon={item.icon}
          >
            {item.items && renderTreeItems(item.items, level + 1)}
          </TreeItem>
        </div>
      );
    });
  };

  return (
    <Box className={styles.sidebar}>
      {renderTreeItems(navs)}
    </Box>
  );
};

export default Sidebar;
