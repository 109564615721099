import { fetchData, fetchDataWithRetry } from "../api";

const getOverview = async (enterpriseId) => {
    return fetchDataWithRetry(`/Enterprise/${enterpriseId}/Dashboard/Overview`);
}

const getUserRank = async (enterpriseId) => {
    const path = `/Enterprise/${enterpriseId}/Dashboard/UserRank`;
    return fetchDataWithRetry(path);
}

const getPackageLearningOverview = async (enterpriseId) => {
    const path = `/Enterprise/${enterpriseId}/Dashboard/PackageLearningOverview`;

    return fetchDataWithRetry(path);
}


const getActiveUserAmount = async (enterpriseId) => {
    const path = `/Enterprise/${enterpriseId}/Dashboard/ActiveUserAmount`;

    return fetchDataWithRetry(path);
}

const getHourlyUsageAnalytics = async (enterpriseId) => {
    const path = `/Enterprise/${enterpriseId}/Dashboard/HourlyUsageAnalytics`;

    return fetchDataWithRetry(path);
}

export default {
    getOverview,
    getUserRank,
    getPackageLearningOverview,
    getActiveUserAmount,
    getHourlyUsageAnalytics
}
