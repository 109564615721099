import { Routes, Route } from 'react-router-dom';
import SchoolMainPage from '../pages/School/SchoolMainPage';
import EnterpriseInfoPage from '../pages/School/EnterpriseInfoPage';
import RecordSummary from '../pages/School/RecordSummary';
import ManagementCourses from '../pages/School/ManagementCourses';
import ManageCourse from '../pages/School/ManageCourse';
import SpringPage from '../pages/School/SpringPage';
import EditCourseMaterials from '../pages/School/EditCourseMaterials';
import ManagementUsers from '../pages/School/ManagementUsers';
import CourseMaterials from '../pages/School/CourseMaterials';
import ReportPage from '../pages/School/ReportPage';
import ImportTask from '../pages/School/ImportTask';
import ManagementEnterprises from '../pages/School/ManagementEnterprises';

const SchoolRoute = () => {
    return (
        <Routes>
            <Route path="/" element={<SchoolMainPage />}>
                <Route path="enterpriseInfo" element={<EnterpriseInfoPage />} />
                <Route path="management/overview" element={<RecordSummary />} />
                <Route path="enterprises" element={<ManagementEnterprises />} />
                <Route path="courses" element={<ManagementCourses />} />
                <Route path="courses/:courseId" element={<ManageCourse />} />
                <Route path="courses/:courseId/springPage" element={<SpringPage />} />
                <Route path="courses/:courseId/edit" element={<EditCourseMaterials />} />
                <Route path="management/users" element={<ManagementUsers />} />
                <Route path="coursematerials" element={<CourseMaterials />} />
                <Route path="reports" element={<ReportPage />} />
                <Route path="import" element={<ImportTask />} />
            </Route>
        </Routes>
    );
};

export default SchoolRoute;