import { useState, useEffect, useRef } from "react";
import {
  Box,
  Grid,
  Checkbox,
  IconButton,
  Button,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  FormLabel,
  OutlinedInput,
  FormHelperText,
  FormControlLabel,
  Switch,
  Stack,
  DialogContentText,
  TextField,
} from "@mui/material";
import Icon from "@mdi/react";
import { mdiPencil, mdiTrashCanOutline, mdiTextBox } from "@mdi/js";
import styles from "./index.module.scss";
import coursesApi from "../../../services/school/courses";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ConfirmedDialog from "../../../components/Common/ConfirmedDialog";
import { PermissionLevel } from "../../../constants/PermissionLevel";
import { useTranslation } from "react-i18next";
import SearchBar from "../../../components/Common/SearchBar";
import Pagination from "../../../components/Common/Pagination"

const CourseAddFormDialog = ({ btnText, fetchData, existData }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [courseName, setCourseName] = useState("");
  const [startTime, setStartTime] = useState();
  const [expireTime, setExpireTime] = useState();
  const [enabled, setEnabled] = useState(true);
  const initHelpText = {
    courseName: "",
    startTime: "",
    expireTime: "",
    timeName: "",
  };
  const [helperText, setHelperText] = useState(initHelpText);
  const [alertOpen, setAlertOpen] = useState(false);
  const userStore = useSelector((state) => state.user);
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const param = useParams();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const verifyInputData = () => {
    let isOK = true;
    let tempHelperText = { ...helperText };

    if (existData.some((p) => p.name === courseName)) {
      setHelperText({
        ...tempHelperText,
        courseName: t("ManagementCourses.PromptText_existed"),
      });
      isOK = false;
    }
    if (courseName === null || courseName.trim() === "") {
      tempHelperText = {
        ...tempHelperText,
        courseName: t("ManagementCourses.PromptText_pleaseEnter"),
      };
      isOK = false;
    }
    if (startTime > expireTime) {
      tempHelperText = {
        ...tempHelperText,
        timeName: t("ManagementCourses.PromptText_timeName"),
      };
      isOK = false;
    }
    if (isOK) {
      setHelperText(initHelpText);
    } else {
      setHelperText(tempHelperText);
    }

    return isOK;
  };

  const handleSendClick = async () => {
    if (!verifyInputData()) return;

    let postData = {
      name: courseName,
      startTime: startTime,
      expireTime: expireTime,
      isEnabled: enabled,
    };
    await coursesApi
      .createCourses(param.enterpriseId, postData)
      .then((res) => {
        fetchData();
        handleClose();
      })
      .catch((err) => {
        alert(t("ManagementCourses.FailAlert") + err.response.data.Message);
      });
  };

  useEffect(() => {
    if (existData.some((p) => p.name === courseName)) {
      setHelperText({
        ...helperText,
        courseName: t("ManagementCourses.PromptText_existed"),
      });
    } else setHelperText({ ...helperText, courseName: "" });
  }, [courseName]);

  useEffect(() => {
    if (!open) {
      setStartTime();
      setExpireTime();
      setCourseName("");
      setEnabled(true);
      fetchData();
    }
  }, [open]);

  const onStartTimeChange = (date) => {
    if (date) setStartTime(new Date(new Date(date).setHours(0, 0, 0)));
    else setStartTime();
  };

  const onExpireTimeChange = (date) => {
    if (date) setExpireTime(new Date(new Date(date).setHours(23, 59, 59)));
    else setExpireTime();
  };

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen}>
        {btnText}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{t("ManagementCourses.DialogTitle")}</DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <FormControl>
                <FormLabel>{t("ManagementCourses.Dialog_className")}</FormLabel>
                <OutlinedInput
                  margin="dense"
                  type="text"
                  fullWidth
                  value={courseName}
                  placeholder={t("ManagementCourses.Dialog_placeholderNameClass")}
                  size="small"
                  onChange={(e) => setCourseName(e.target.value)}
                  variant="outlined"
                />
                <FormHelperText error={helperText.courseName != ""}>
                  {helperText.courseName}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item container direction="row">
              <FormControl>
                <FormLabel>{t("ManagementCourses.Dialog_classValidity")}</FormLabel>
                <FormControl>
                  <DatePicker
                    className={styles.datepicker}
                    selected={startTime}
                    popperPlacement="right-end"
                    onChange={(date) => onStartTimeChange(date)}
                    placeholderText={t(
                      "ManagementCourses.Dialog_placeholderBeginTime"
                    )}
                    dateFormat="yyyy/MM/dd"
                    customInput={
                      <OutlinedInput
                        size="small"
                        sx={{ m: 1, minWidth: 120 }}
                      />
                    }
                    withPortal={windowSize.current[0] < 600}
                  ></DatePicker>
                </FormControl>
                <FormControl>
                  <FormLabel className={styles.datepickerTo}>
                    {t("ManagementCourses.Dialog_to")}
                  </FormLabel>
                  <DatePicker
                    className={styles.datepicker}
                    selected={expireTime}
                    popperPlacement="right-end"
                    onChange={(date) => onExpireTimeChange(date)}
                    placeholderText={t(
                      "ManagementCourses.Dialog_placeholderEndTime"
                    )}
                    dateFormat="yyyy/MM/dd"
                    customInput={
                      <OutlinedInput
                        size="small"
                        sx={{ m: 1, minWidth: 120 }}
                      />
                    }
                    withPortal={windowSize.current[0] < 600}
                  ></DatePicker>
                </FormControl>
                <FormHelperText>
                  {t("ManagementCourses.Dialog_helperText")}
                </FormHelperText>
                <FormHelperText error={helperText.timeName != ""}>
                  {helperText.timeName}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={enabled}
                      onChange={(e) => setEnabled(e.target.checked)}
                    />
                  }
                  label={t("ManagementCourses.Dialog_enableCheckbox")}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            {t("ManagementCourses.Dialog_cancelCreateClassBtn")}
          </Button>
          <Button variant="contained" onClick={handleSendClick}>
            {t("ManagementCourses.Dialog_createClassBtn")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const ManagementCourses = () => {
  const { t } = useTranslation();
  // 所有資料
  const [coursesList, setCoursesList] = useState([]);
  const [searchText, setSearchText] = useState("");
  // 輸入的值
  const [inputText, setInputText] = useState("");
  // 比較輸入的值與原本搜尋的值是否相同
  const [prevValue, setPrevValue] = useState(null);
  // 符合搜尋內容的資料
  const [searchList, setSearchList] = useState([]);
  // 目前所在頁數
  const [currentPage, setCurrentPage] = useState(1);
  // 每頁顯示項目數量
  const itmesPerPage = 20;
  // 總共需要的頁數 
  const totalPages = Math.ceil(searchList.length / itmesPerPage);
  // 計算該頁面最後 item 為第幾個。
  const indexOfLastItem = currentPage * itmesPerPage;
  // 計算該頁面開始 item 為第幾個。
  const indexOfFirstItem = indexOfLastItem - itmesPerPage;
  // 提取該頁面要顯示的資料
  const currentItems = searchList.slice(indexOfFirstItem, indexOfLastItem);

  const [removeConfirmDialog, setRemoveConfirmDialog] = useState({
    open: false,
    targets: [],
  });
  const [statusConfirmDialog, setStatusConfirmDialog] = useState({
    open: false,
    targetCourse: null,
  });
  const params = useParams();
  const navigate = useNavigate();

  const authStore = useSelector((state) => state.auth);

  // 從 <SearchBar> 取得搜尋欄位值
  const handleInputText = (data) => {
    setInputText(data);
  };

  // 從 <PaginationBar> 取得當前所在頁數
  const handleCurrentPage = (page) => {
    setCurrentPage(page)
  }

  const fetchCourses = async () => {
    await coursesApi.getCourses(params.enterpriseId, searchText).then((res) => {
      setCoursesList(
        res.data.map((item) => ({
          ...item,
          checked: false,
        }))
      );

      if (inputText == "") {
        setSearchList(
          res.data.map((item) => ({
            ...item,
            checked: false,
          }))
        );
      } else {
        // 提取現有 Data 中，符合搜尋內容的 Data。
        let matchData = [];
        for (let i = 0; i < res.data.length; i++) {
          let data = res.data[i].name;
          if (data.indexOf(inputText) != -1) {
            matchData.push(res.data[i]);
          }
        }
        setSearchList(matchData.map((item) => ({
          ...item,
          checked: false,
        })));
      }
    });

  };

  const handleSelected = (event, targetItem) => {
    let newList = searchList.map((item) => {
      if (item.id === targetItem.id) {
        return { ...item, checked: event.target.checked };
      }
      return item;
    });
    setSearchList(newList);
  };

  const onBatchRemoveBtnClcik = () => {
    const targets = searchList.filter((p) => p.checked);
    setRemoveConfirmDialog({
      ...removeConfirmDialog,
      open: true,
      targets: targets,
    });
  };

  const onRemoveCancleClick = () => {
    setRemoveConfirmDialog({
      ...removeConfirmDialog,
      open: false,
      targets: [],
    });
  };

  const onRemoveConfirmedClick = async () => {
    let targets = removeConfirmDialog.targets.map((p) => p.id);
    await coursesApi.removeCourses(params.enterpriseId, targets).then((res) => {
      fetchCourses();
      setRemoveConfirmDialog({
        ...removeConfirmDialog,
        open: false,
        targets: [],
      });
    });
  };

  const onStatusCancleClick = () => {
    setStatusConfirmDialog({
      ...statusConfirmDialog,
      open: false,
      targetCourse: null,
    });
  };

  const onStatusConfirmedClick = async () => {
    let targetStatus =
      statusConfirmDialog.targetCourse.status === "Publish"
        ? "UnPublish"
        : "Publish";
    await coursesApi
      .changeStatus(
        params.enterpriseId,
        statusConfirmDialog.targetCourse.id,
        targetStatus
      )
      .then((res) => {
        fetchCourses();
        setStatusConfirmDialog({
          ...statusConfirmDialog,
          open: false,
          targetCourse: null,
        });
      });
  };

  const onToggleStatusChange = (event, item) => {
    if (event.target.checked && item.status === "Publish") return;
    if (!event.target.checked && item.status !== "Publish") return;
    setStatusConfirmDialog({
      ...statusConfirmDialog,
      open: true,
      targetCourse: item,
    });
  };

  useEffect(() => {
    fetchCourses();
  }, [searchText]);

  // 搜尋值改變時更新 searchList 
  useEffect(() => {
    if (inputText !== prevValue) {
      setPrevValue(inputText);
      let matchData = [];

      for (let i = 0; i < coursesList.length; i++) {
        let data = coursesList[i].name;
        if (data.indexOf(inputText) != -1) {
          matchData.push(coursesList[i]);
        }
        setSearchList(matchData);
        setCurrentPage(1);
      }
    }
  }, [inputText, prevValue])


  const displayStatus = (item) => {
    switch (item.status) {
      case "UnPubliish":
        return <span>{t("ManagementCourses.ClassStatus_unPubliish")}</span>;
      case "Publish":
        if (item.startTime) {
          if (Date.now() < new Date(item.startTime))
            return <span>{t("ManagementCourses.ClassStatus_unPubliish")}</span>;
        }
        if (item.expireTime) {
          if (Date.now() > new Date(item.expireTime))
            return (
              <span style={{ color: "#d50000" }}>
                {t("ManagementCourses.ClassStatus_expire")}
              </span>
            );
        }
        return (
          <span style={{ color: "#62AF00" }}>
            {t("ManagementCourses.ClassStatus_publiish")}
          </span>
        );
      case "Archive":
        return (
          <span style={{ color: "#ff9900" }}>
            {t("ManagementCourses.ClassStatus_archive")}
          </span>
        );
    }
  };

  return (
    <>
      <Box>
        <div className={styles.title}>{t("ManagementCourses.Title")}</div>
        <div className={styles.description}>
          {t("ManagementCourses.Description")}
        </div>
        <Box className={styles.main_content}>
          <Grid item className={styles.card} container>
            <div className={styles.card_title}>
              {t("ManagementCourses.Card_title")}
            </div>
            <div className={styles.card_subtitle}></div>
            <Grid
              container
              item
              alignItems="start"
              direction="row"
              justifyContent="space-between"
              display="flex"
              margin="0.5rem 0"
              gap={1}
            >
              {authStore.permissionLevel >= PermissionLevel.EnterpriseAdmin && (
                <>
                  <SearchBar InputTextValue={handleInputText} />
                  <div style={{ display: "flex", gap: "0.5rem" }}>
                    <Button
                      variant="outlined"
                      disabled={!searchList?.some((p) => p.checked)}
                      onClick={() => onBatchRemoveBtnClcik()}
                    >
                      {t("ManagementCourses.DeleteBtn")}
                    </Button>
                    <CourseAddFormDialog
                      btnText={t("ManagementCourses.AddClassBtn")}
                      fetchData={fetchCourses}
                      existData={coursesList}
                    />
                  </div>
                </>
              )}
            </Grid>
            <div>
              <table className={styles.table} cellSpacing="0">
                <thead>
                  <tr>
                    <th width="40"></th>
                    <th>{t("ManagementCourses.Table_class")}</th>
                    <th>{t("ManagementCourses.Table_student")}</th>
                    <th>{t("ManagementCourses.Table_teacher")}</th>
                    <th>{t("ManagementCourses.Table_course")}</th>
                    <th colSpan="3">{t("ManagementCourses.Table_validity")}</th>
                    <th style={{ paddingLeft: "20px" }}>
                      {t("ManagementCourses.Table_enable")}
                    </th>
                    <th>{t("ManagementCourses.Table_status")}</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td>
                          {authStore.permissionLevel >=
                            PermissionLevel.EnterpriseAdmin && (
                              <Checkbox
                                checked={item.checked}
                                onChange={(event) => handleSelected(event, item)}
                              />
                            )}
                        </td>
                        <td data-label={`${t("ManagementCourses.Table_class")}:`}>
                          <Link href="#" onClick={() => navigate(`${item.id}`)}>
                            {item.name}
                          </Link>
                        </td>
                        <td data-label={`${t("ManagementCourses.Table_student")}:`}>
                          {item.userAmount}
                        </td>
                        <td data-label={`${t("ManagementCourses.Table_teacher")}:`}>
                          {item.teacherAmount}
                        </td>
                        <td data-label={`${t("ManagementCourses.Table_course")}:`}>
                          {item.examPackageAmount}
                        </td>
                        <td
                          data-label={`${t("ManagementCourses.Table_validity")}:`}
                          className={styles.only_mobile}
                        >
                          {item.startTime != null
                            ? new Date(item.startTime).toLocaleDateString()
                            : ""}
                          &nbsp;~&nbsp;
                          {item.expireTime != null
                            ? new Date(item.expireTime).toLocaleDateString()
                            : ""}
                        </td>
                        <td
                          data-label={`${t("ManagementCourses.Table_validity")}:`}
                          width="30"
                          style={{ textAlign: "center" }}
                          className={styles.only_pc}
                        >
                          {item.startTime != null
                            ? new Date(item.startTime).toLocaleDateString()
                            : "-"}
                        </td>
                        <td data-label="" width="10" className={styles.only_pc}>
                          ~
                        </td>
                        <td
                          data-label=""
                          width="30"
                          style={{ textAlign: "center" }}
                          className={styles.only_pc}
                        >
                          {item.expireTime != null
                            ? new Date(item.expireTime).toLocaleDateString()
                            : "-"}
                        </td>
                        <td data-label={`${t("ManagementCourses.Table_enable")}:`}>
                          <Switch
                            checked={item.status === "Publish"}
                            disabled={
                              authStore.permissionLevel <
                              PermissionLevel.EnterpriseAdmin
                            }
                            onChange={(event) =>
                              onToggleStatusChange(event, item)
                            }
                          />
                        </td>
                        <td data-label={`${t("ManagementCourses.Table_status")}:`}>
                          <div>{displayStatus(item)}</div>
                        </td>
                        {/* <td>
                                                        <IconButton className={styles.btn_edit} >
                                                            <Icon path={mdiPencil}
                                                                size={1}
                                                            />
                                                        </IconButton>
                                                        <IconButton className={styles.btn_del} >
                                                            <Icon path={mdiTrashCanOutline}
                                                                size={1}
                                                            />
                                                        </IconButton>
                                                    </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Grid>
        </Box>
        <Pagination inputText={inputText} totalPages={totalPages} currentPage={handleCurrentPage} dataLength={searchList.length} />
      </Box>
      <ConfirmedDialog
        open={removeConfirmDialog.open}
        title={t("ManagementCourses.DeleteDialog_title")}
        onCancleClick={onRemoveCancleClick}
        onConfirmedClcik={onRemoveConfirmedClick}
      >
        <>
          {removeConfirmDialog.targets?.length > 0 && (
            <div>
              {removeConfirmDialog.targets?.length > 1 ? (
                <>{t("ManagementCourses.DeleteDialog_removeClass", { var: removeConfirmDialog?.targets.length })}</>
              ) : (
                <>
                  {t("ManagementCourses.DeleteDialog_removeText")}
                  <strong>{removeConfirmDialog?.targets[0]?.name}</strong>？
                </>
              )}
            </div>
          )}
        </>
      </ConfirmedDialog>

      <ConfirmedDialog
        open={statusConfirmDialog.open}
        title={`${statusConfirmDialog.targetCourse?.status === "Publish"
          ? t("ManagementCourses.EnableDialog_disableTitle")
          : t("ManagementCourses.EnableDialog_enableTitle")
          }${t("ManagementCourses.EnableDialog_title")}`}
        onCancleClick={onStatusCancleClick}
        onConfirmedClcik={onStatusConfirmedClick}
      >
        <div>
          {t("ManagementCourses.EnableDialog_confirmSatus", {
            var:
              statusConfirmDialog.targetCourse?.status === "Publish"
                ? t("ManagementCourses.EnableDialog_disableText")
                : t("ManagementCourses.EnableDialog_enableText")
          })}
          <strong>{statusConfirmDialog.targetCourse?.name}</strong>？
        </div>
      </ConfirmedDialog>
    </>
  );
};

export default ManagementCourses;
