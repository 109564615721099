import { Routes, Route } from 'react-router-dom';
import AdminMainPage from '../pages/Admin/AdminMainPage';
import ManagementEnterprises from '../pages/Admin/ManagementEnterprises';

const AdminRoute = () => {
    return (
        <Routes>
            <Route path="/" element={<AdminMainPage />}>
                <Route path="enterprises" element={<ManagementEnterprises />} />
            </Route>
        </Routes>
    );
};

export default AdminRoute;