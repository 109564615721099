import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import schoolApi from "../../../services/school/enterpriseInfo";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Icon from "@mdi/react";
import {
  mdiPencil,
  mdiClose,
} from "@mdi/js";
import { Box, Grid, Card, CardContent, Typography, Divider } from "@mui/material";
import dashboardApi from "../../../services/enterprise/dashboard";
import ActiveStatusHistogramChart from "../../../components/Common/ActiveStatusHistogramChart";

const TopUserRankCard = () => {
  const [userRankList, setUserRankList] = useState([]);
  const params = useParams();
  useEffect(() => {

    const fetchData = async () => {
      await dashboardApi.getUserRank(params.enterpriseId)
        .then((res) => {
          setUserRankList(res.data);
        });
    }
    fetchData();
  }, [])

  return (
    <Card className={styles.card}>
      <CardContent>
        <Typography variant="h7">Top 5 排名</Typography>
        <Typography variant="body1"></Typography>
      </CardContent>
    </Card>)
}

const PackageLearningAmountCard = () => {
  const [packageLearningInfo, setPackageLearningInfo] = useState();
  const params = useParams();
  useEffect(() => {

    const fetchData = async () => {
      await dashboardApi.getPackageLearningOverview(params.enterpriseId)
        .then((res) => {
          setPackageLearningInfo(res.data);
        });
    }
    fetchData();
  }, [])

  return (
    <Card className={styles.card}>
      <CardContent>
        <Typography variant="h7">課程使用比率</Typography>
      </CardContent>
    </Card>)
}

const HourlyUsageAnalyticsCard = () => {
  const { t, i18n } = useTranslation();
  const [hourlyRecords, setHourlyRecords] = useState([]);
  const [data, setData] = useState([]);

  const setChartDate = (record) => {
    if (!record) {
      setData([]);
    } else {
      const dataRecords = hourlyRecords.map((p) => {
        return { time: p.hour, value: p.totalUserAmount };
      });
      // 每 4 組 為一筆 data
      const groupedRecords = dataRecords.reduce((acc, current, index) => {
        const groupIndex = Math.floor(index / 4);
        if (!acc[groupIndex]) {
          acc[groupIndex] = { time: 0, value: 0 };
        }

        // *4 : 使每筆 data 相差 4小時，+4 : 從 04 開始
        acc[groupIndex].time =
          groupIndex * 4 +
          4 +
          t("RecordSummary.ActiveStatusChart_chartDate_hour");
        let a = acc[groupIndex].time.toString().padStart(3, "0");
        acc[groupIndex].time = a;
        acc[groupIndex].value += current.value;
        return acc;
      }, []);

      setData(groupedRecords);
    }
  };

  useEffect(() => {
    if (!hourlyRecords) return;
    setChartDate(hourlyRecords);
  }, [hourlyRecords, i18n.language]);

  const params = useParams();
  useEffect(() => {

    const fetchData = async () => {
      await dashboardApi.getHourlyUsageAnalytics(params.enterpriseId)
        .then((res) => {
          setHourlyRecords(res.data);
        });
    }
    fetchData();
  }, [])


  return (
    <Card className={styles.card}>
      <CardContent>
        <Typography variant="h7">尖峰時段</Typography>
        <ActiveStatusHistogramChart data={data} />
      </CardContent>
    </Card>
  );
};

const UserActivityChartCard = () => {
  const [activityList, setActivityList] = useState([]);
  const params = useParams();
  useEffect(() => {

    const fetchData = async () => {
      await dashboardApi.getActiveUserAmount(params.enterpriseId)
        .then((res) => {
          setActivityList(res.data.dataPoints);
        });
    }
    fetchData();
  }, [])

  return (
    <Card className={styles.card}>
      <CardContent>
        <Typography variant="h7">系統活躍人數</Typography>
        <Typography variant="body1"></Typography>
      </CardContent>
    </Card>)
};

const OverviewPage = () => {
  const [overviewData, setOverviewData] = useState();
  const params = useParams();
  const enterpriseStore = useSelector(state => state.enterprise);



  useEffect(() => {
    const fetchData = async () => {
      await dashboardApi.getOverview(params.enterpriseId)
        .then((res) => {
          setOverviewData(res.data);
        });
    }
    fetchData();
  }, [])

  return (
    <Box className={styles.dashboard}>
      <Box sx={{ m: 1 }}>
        <Grid
          container
          spacing={2}
          justifyContent={"space-between"}
          alignItems="center">
          <Grid item>
            <Typography variant="h5">全體概覽分析</Typography>
          </Grid>
          <Grid item>
            <div className={styles.enterpirseInfo}>
              <Grid container spacing={3}>
                <Grid item>
                  企業ID：{enterpriseStore.info?.id}
                </Grid>
                <Grid item>
                  目前上線人數： {enterpriseStore?.onLineUserAmount}
                </Grid>
                <Grid item>
                  期間： {enterpriseStore?.startDate} - {enterpriseStore?.endDate}
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Card className={styles.card}>
              <CardContent>
                <Typography variant="h7">人員達標率</Typography>
                <div>
                  {overviewData?.passRate}
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={8}>
            <TopUserRankCard />
          </Grid>
          <Grid item xs={12} md={4}>
            <Card className={styles.card}>
              <CardContent>
                <Typography variant="h7">使用率</Typography>
                <div>
                  {overviewData?.usageRate}
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <PackageLearningAmountCard />
          </Grid>
          <Grid item xs={12} md={4}>
            <Card className={styles.card}>
              <CardContent>
                <Typography variant="h7">已生成題目數</Typography>
                <div>
                  {overviewData?.generatedQuestionCount}/{overviewData?.availableQuestionsCount}
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <UserActivityChartCard />
          </Grid>
          <Grid item xs={12} md={4}>
            <HourlyUsageAnalyticsCard />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default OverviewPage;
