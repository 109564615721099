import { useState, useEffect } from "react";
import {
  Stack,
  Button,
  Box,
  Grid,
  Checkbox,
  IconButton,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import Icon from "@mdi/react";
import {
  mdiSquareEditOutline,
  mdiTrashCan,
  mdiEmailOutline,
  mdiClipboardAccountOutline,
  mdiPencil,
  mdiAccount,
  mdiTrashCanOutline,
  mdiTurnstile,
} from "@mdi/js";
import styles from "./index.module.scss";
import usersApi from "../../../services/school/users";
import schoolApi from "../../../services/school/enterpriseInfo";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../hooks/auth";
import { PermissionLevel } from "../../../constants/PermissionLevel";
import UserProfileFormDialog from "../../../components/Common/UserProfileFormDialog";
import ConfirmedDialog from "../../../components/Common/ConfirmedDialog";
import UserSendMailDialog from "../../../components/Common/UserSendMailDialog";
import user from "../../../reducers/user";
import { useUserUtils } from "../../../hooks/userUtils";
import { useTranslation } from "react-i18next";
import Pagination from "../../../components/Common/Pagination"
import SearchBar from "../../../components/Common/SearchBar";

const ManagementUsers = () => {
  const { t } = useTranslation();
  // 輸入的值
  const [inputText, setInputText] = useState("");
  // 比較輸入的值與原本搜尋的值是否相同
  const [prevValue, setPrevValue] = useState(null);
  // 符合搜尋內容的資料
  const [searchList, setSearchList] = useState([]);
  // 目前所在頁數
  const [currentPage, setCurrentPage] = useState(1);
  // 每頁顯示項目數量
  const itmesPerPage = 20;
  // 總共需要的頁數 
  const totalPages = Math.ceil(searchList.length / itmesPerPage);
  // 計算該頁面最後 item 為第幾個。
  const indexOfLastItem = currentPage * itmesPerPage;
  // 計算該頁面開始 item 為第幾個。
  const indexOfFirstItem = indexOfLastItem - itmesPerPage;
  // 提取該頁面要顯示的資料
  const currentItems = searchList.slice(indexOfFirstItem, indexOfLastItem);
  const profileDialogInit = {
    open: false,
    userData: null,
  };
  const [userLimit, setUserLimit] = useState("");
  const [info, setInfo] = useState({
    studentEnabled: 0,
    suspended: 0,
    expire: 0,
    teacher: 0,
    admin: 0,
  });
  const [filterRoles, setFilterRoles] = useState({
    admin: true,
    teacher: true,
    student: true,
  });
  const [filterStatus, setFilterStatus] = useState({
    isEnabled: true,
    isSuspended: true,
    isExpired: true,
  });
  const [profileDialog, setProfileDialog] = useState(profileDialogInit);
  const [enterpriseInfo, setEnterpriseInfo] = useState();
  const [userList, setUserList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [removeConfirmDialog, setRemoveConfirmDialog] = useState({
    open: false,
    targets: [],
  });
  const [statusConfirmDialog, setStatusConfirmDialog] = useState({
    open: false,
    targetUser: null,
  });

  const { displayRoles, displayStatus } = useUserUtils();
  const params = useParams();

  const roles = [
    {
      id: "Student",
      name: t("ManagementUsers.Roles_name_student"),
    },
    {
      id: "Teacher",
      name: t("ManagementUsers.Roles_name_teacher"),
    },
    {
      id: "Admin",
      name: t("ManagementUsers.Roles_name_administrator"),
    },
  ];

  // 從 <SearchBar> 取得搜尋欄位值
  const handleInputText = (data) => {
    setInputText(data);
  };
  // 從 <PaginationBar> 取得當前所在頁數
  const handleCurrentPage = (page) => {
    setCurrentPage(page)
  }
  // 搜尋值改變時更新 searchList 
  useEffect(() => {
    if (inputText !== prevValue) {
      setPrevValue(inputText);
      let matchData = [];

      for (let i = 0; i < userList.length; i++) {
        let fullNameData = userList[i].fullName;
        let accountData = userList[i].enterpriseUserId;
        if (fullNameData.indexOf(inputText) != -1 || accountData.indexOf(inputText) != -1) {
          matchData.push(userList[i]);
        }
        setSearchList(matchData);
        setCurrentPage(1);
      }
    }
  }, [inputText, prevValue])

  useEffect(() => {
    if (!searchList) return;
    const suspended = searchList.filter((p) =>
      ["Disabled", "Suspended"].includes(p.userStatus)
    ).length;
    const expire = searchList
      .filter((p) => p.expireTime)
      .filter((p) => Date.now() > new Date(p.expireTime)).length;
    const admin = searchList.filter(
      (p) =>
        p.roles.includes("SuperAdmin") ||
        p.roles.includes("DealerAdmin") ||
        p.roles.includes("EnterpriseAdmin")
    ).length;
    const teacher = searchList.filter((p) => p.roles.includes("Teacher")).length;
    const studentEnabled = searchList
      .filter((p) => p.userStatus == "Enabled")
      .filter((p) => p.roles.includes("Student")).length;
    setInfo({
      studentEnabled: studentEnabled,
      suspended: suspended,
      expire: expire,
      teacher: teacher,
      admin: admin,
    });
  }, [searchList]);

  useEffect(() => {
    if (!enterpriseInfo) return;
    setUserLimit(enterpriseInfo.userLimit ?? "∞");
  }, [enterpriseInfo]);

  useEffect(() => {
    fetchEnterpriseInfo();
    fetchUsers();
  }, []);

  const fetchEnterpriseInfo = async () => {
    await schoolApi.getInfo(params.enterpriseId).then((res) => {
      setEnterpriseInfo(res.data);
    });
  };

  const fetchUsers = async () => {
    await usersApi.getUsers(params.enterpriseId, searchText).then((res) => {
      setUserList(
        res.data.map((item) => ({
          ...item,
          checked: false,
        }))
      );
      if (inputText == "") {
        setSearchList(
          res.data.map((item) => ({
            ...item,
            checked: false,
          }))
        );
      } else {
        // 提取現有 Data 中，符合搜尋內容的 Data。
        let matchData = [];
        for (let i = 0; i < res.data.length; i++) {
          let fullNameData = userList[i].fullName;
          let accountData = userList[i].enterpriseUserId;
          if (fullNameData.indexOf(inputText) != -1 || accountData.indexOf(inputText) != -1) {
            matchData.push(res.data[i]);
          }
        }
        setSearchList(matchData.map((item) => ({
          ...item,
          checked: false,
        })));
      }
    });
  };

  const onProfileDialogClose = (userInfo) => {
    if (userInfo) fetchUsers();
    setProfileDialog(profileDialogInit);
  };
  const handleSelected = (event, targetItem) => {
    let newList = searchList.map((item) => {
      if (item.id === targetItem.id) {
        return { ...item, checked: event.target.checked };
      }
      return item;
    });
    setSearchList(newList);
  };

  const onBatchRemoveBtnClcik = () => {
    const targets = searchList.filter((p) => p.checked);
    setRemoveConfirmDialog({
      ...removeConfirmDialog,
      open: true,
      targets: targets,
    });
  };

  const onRemoveCancleClick = () => {
    setRemoveConfirmDialog({
      ...removeConfirmDialog,
      open: false,
      targets: [],
    });
  };

  const onRemoveConfirmedClick = async () => {
    let targets = removeConfirmDialog.targets.map((p) => p.id);
    await usersApi.removeUsers(params.enterpriseId, targets).then((res) => {
      fetchUsers();
      setRemoveConfirmDialog({
        ...removeConfirmDialog,
        open: false,
        targets: [],
      });
    });
  };

  const onStatusCancleClick = () => {
    setStatusConfirmDialog({
      ...statusConfirmDialog,
      open: false,
      targetUser: null,
    });
  };

  const onStatusConfirmedClick = async () => {
    let targetStatus =
      statusConfirmDialog.targetUser.userStatus === "Enabled"
        ? "Suspended"
        : "Enabled";
    await usersApi
      .changeStatus(
        params.enterpriseId,
        statusConfirmDialog.targetUser.id,
        targetStatus
      )
      .then((res) => {
        fetchUsers();
        setStatusConfirmDialog({
          ...statusConfirmDialog,
          open: false,
          targetUser: null,
        });
      });
  };

  const onToggleStatusClick = async (user) => {
    setStatusConfirmDialog({
      ...statusConfirmDialog,
      open: true,
      targetUser: user,
    });
  };

  return (
    <>
      <Box>
        <Box>
          <div className={styles.title}>{t("ManagementUsers.Title")}</div>
          <div className={styles.description}>
            {t("ManagementUsers.Description")}
          </div>
        </Box>
        {/* <Stack direction="row" spacing={1}>

            </Stack> */}
        <Box className={styles.main_content}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={3}>
              <div className={styles.value_card}>
                <div className={styles.card_title}>
                  {t("ManagementUsers.CardTitle_studentsAccounts")}
                </div>
                <div className={styles.card_value}>{userLimit}</div>
                <div className={styles.card_remark}>&nbsp;</div>
              </div>
            </Grid>
            <Grid item xs={6} md={3}>
              <div className={styles.value_card}>
                <div className={styles.card_title}>
                  {" "}
                  {t("ManagementUsers.CardTitle_enabledAccounts")}
                </div>
                <div className={styles.card_value}>{info.studentEnabled}</div>
                <div className={styles.card_remark}>&nbsp;</div>
              </div>
            </Grid>
            <Grid item xs={6} md={3}>
              <div className={styles.value_card}>
                <div className={styles.card_title}>
                  {t("ManagementUsers.CardTitle_deactivatedAccounts")}
                </div>
                <div className={styles.card_value}>{info.suspended}</div>
                <div className={styles.card_remark}>&nbsp;</div>
              </div>
            </Grid>
            <Grid item xs={6} md={3}>
              <div className={styles.value_card}>
                <div className={styles.card_title}>
                  {t("ManagementUsers.CardTitle_expiredAccounts")}
                </div>
                <div className={styles.card_value}>{info.expire}</div>
                <div className={styles.card_remark}>&nbsp;</div>
              </div>
            </Grid>
          </Grid>
          <Grid item className={styles.card} container>
            <div className={styles.card_title}>
              {t("ManagementUsers.CardTitle_UserList")}
            </div>
            <div className={styles.card_subtitle}></div>
            <Grid
              container
              item
              alignItems="start"
              direction="row"
              justifyContent="space-between"
              display="flex"
              margin="0.5rem 0"
              gap={1}
            >
              <SearchBar InputTextValue={handleInputText} />
              <div style={{ display: "flex", gap: "0.5rem" }}>
                <Button
                  variant="outlined"
                  disabled={!searchList?.some((p) => p.checked)}
                  onClick={() => onBatchRemoveBtnClcik()}
                >
                  {t("ManagementUsers.DeleteBtn")}
                </Button>
                <Button
                  variant="contained"
                  onClick={() => setProfileDialog({ open: true })}
                >
                  {t("ManagementUsers.AddBtn")}
                </Button>
              </div>
            </Grid>
            <Grid
              container
              item
              alignItems="start"
              direction="column"
              justifyContent="flex-start"
            >
              <Grid container item direction="row" alignItems={"center"}>
                <div>{t("ManagementUsers.CheckBox_title_roles")}</div>
                <Stack direction={"row"} gap={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterRoles.admin}
                        onClick={(e) =>
                          setFilterRoles({
                            ...filterRoles,
                            admin: e.target.checked,
                          })
                        }
                      />
                    }
                    label={t("ManagementUsers.CheckBox_roles_administrator")}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterRoles.teacher}
                        onClick={(e) =>
                          setFilterRoles({
                            ...filterRoles,
                            teacher: e.target.checked,
                          })
                        }
                      />
                    }
                    label={t("ManagementUsers.CheckBox_roles_teacher")}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterRoles.student}
                        onClick={(e) =>
                          setFilterRoles({
                            ...filterRoles,
                            student: e.target.checked,
                          })
                        }
                      />
                    }
                    label={t("ManagementUsers.CheckBox_roles_student")}
                  />
                </Stack>
              </Grid>
              <Grid container item direction="row" alignItems={"center"}>
                <div>{t("ManagementUsers.CheckBox_title_account")}</div>
                <Stack direction={"row"} gap={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterStatus.isEnabled}
                        onClick={(e) =>
                          setFilterStatus({
                            ...filterStatus,
                            isEnabled: e.target.checked,
                          })
                        }
                      />
                    }
                    label={t("ManagementUsers.CheckBox_status_enabled")}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterStatus.isSuspended}
                        onClick={(e) =>
                          setFilterStatus({
                            ...filterStatus,
                            isSuspended: e.target.checked,
                          })
                        }
                      />
                    }
                    label={t("ManagementUsers.CheckBox_status_deactivated")}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterStatus.isExpired}
                        onClick={(e) =>
                          setFilterStatus({
                            ...filterStatus,
                            isExpired: e.target.checked,
                          })
                        }
                      />
                    }
                    label={t("ManagementUsers.CheckBox_status_expired")}
                  />
                </Stack>
              </Grid>
            </Grid>
            <div>
              <table className={styles.table} cellSpacing="0">
                <thead>
                  <tr>
                    <th width="40"></th>
                    <th>{t("ManagementUsers.Table_header_account")}</th>
                    <th>{t("ManagementUsers.Table_header_name")}</th>
                    <th>{t("ManagementUsers.Table_header_validity")}</th>
                    <th>{t("ManagementUsers.Table_header_status")}</th>
                    <th>{t("ManagementUsers.Table_header_role")}</th>
                    <th style={{ paddingLeft: "10px" }}>
                      {t("ManagementUsers.Table_header_function")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems
                    .filter((item) => {
                      if (
                        filterRoles.admin &&
                        item.roles.some((p) => p.indexOf("Admin") > 0)
                      )
                        return true;
                      else if (
                        filterRoles.teacher &&
                        item.roles.includes("Teacher")
                      )
                        return true;
                      else if (
                        filterRoles.student &&
                        (item.roles.includes("Student") ||
                          item.roles.length == 0)
                      )
                        return true;
                      return false;
                    })
                    .filter((item) => {
                      if (
                        filterStatus.isEnabled &&
                        item.userStatus === "Enabled" &&
                        (!item.expireTime ||
                          new Date() < new Date(item.expireTime))
                      )
                        return true;
                      if (
                        filterStatus.isSuspended &&
                        (item.userStatus === "Disabled" ||
                          item.userStatus === "Suspended")
                      )
                        return true;
                      if (
                        filterStatus.isExpired &&
                        item.userStatus === "Enabled" &&
                        item.expireTime &&
                        new Date() >= new Date(item.expireTime)
                      )
                        return true;
                      return false;
                    })
                    .map((item) => {
                      return (
                        <tr key={`${item.id}`}>
                          <td>
                            <Checkbox
                              checked={item.checked}
                              onChange={(event) => handleSelected(event, item)}
                            />
                          </td>
                          <td data-label={`${t("ManagementUsers.Table_header_account")}:`}>
                            {item.enterpriseUserId}
                          </td>
                          <td data-label={`${t("ManagementUsers.Table_header_name")}:`}>
                            {item.fullName ?? "-"}
                          </td>
                          <td data-label={`${t("ManagementUsers.Table_header_validity")}:`}>
                            {item.expireTime != null
                              ? new Date(item.expireTime).toLocaleDateString()
                              : "-"}
                          </td>
                          <td data-label={`${t("ManagementUsers.Table_header_status")}:`}>
                            <div
                              className={styles.btn_status}
                              onClick={() => {
                                onToggleStatusClick(item);
                              }}
                            >
                              {displayStatus(item)}
                            </div>
                          </td>
                          <td data-label={`${t("ManagementUsers.Table_header_role")}:`}>
                            {displayRoles(item)}
                          </td>
                          <td>
                            <IconButton
                              className={styles.btn_edit}
                              onClick={() =>
                                setProfileDialog({ open: true, userData: item })
                              }
                            >
                              <Icon path={mdiPencil} size={1} />
                            </IconButton>
                            <IconButton
                              className={styles.btn_del}
                              onClick={() =>
                                setRemoveConfirmDialog({
                                  ...removeConfirmDialog,
                                  open: true,
                                  targets: [item],
                                })
                              }
                            >
                              <Icon path={mdiTrashCanOutline} size={1} />
                            </IconButton>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </Grid>
        </Box>
        <Pagination inputText={inputText} totalPages={totalPages} currentPage={handleCurrentPage} dataLength={searchList.length} />
      </Box>
      <UserProfileFormDialog
        open={profileDialog.open}
        userData={profileDialog.userData}
        onClose={onProfileDialogClose}
      />

      <ConfirmedDialog
        open={removeConfirmDialog.open}
        title={t("ManagementUsers.DeleteBtn_dialog_title")}
        onCancleClick={onRemoveCancleClick}
        onConfirmedClcik={onRemoveConfirmedClick}
      >
        <>
          {removeConfirmDialog.targets?.length > 0 && (
            <div>
              {removeConfirmDialog.targets?.length > 1 ? (
                <>
                  {t("ManagementUsers.DeleteBtn_dialog_removeAmount", { var: removeConfirmDialog?.targets.length })}
                </>
              ) : (
                <>
                  {t("ManagementUsers.DeleteBtn_dialog_text")}
                  <strong>
                    {removeConfirmDialog?.targets[0]?.enterpriseUserId}
                  </strong>
                  ？
                </>
              )}
            </div>
          )}
        </>
      </ConfirmedDialog>

      <ConfirmedDialog
        open={statusConfirmDialog.open}
        title={`${statusConfirmDialog.targetUser?.userStatus === "Enabled"
          ? t("ManagementUsers.Status_dialog_title_deactivate")
          : t("ManagementUsers.Status_dialog_title_activate")
          } ${t("ManagementUsers.Status_dialog_text_account")}`}
        onCancleClick={onStatusCancleClick}
        onConfirmedClcik={onStatusConfirmedClick}
      >
        <div>
          {t("ManagementUsers.Status_dialog_text_status",
            {
              var: statusConfirmDialog.targetUser?.userStatus === "Enabled"
                ? t("ManagementUsers.Status_dialog_text_deactivate")
                : t("ManagementUsers.Status_dialog_text_activate")
            })}
          <strong>{statusConfirmDialog.targetUser?.enterpriseUserId}</strong>？
        </div>
      </ConfirmedDialog>
    </>
  );
};

export default ManagementUsers;
