import { useState, useEffect, useRef, Children } from "react";
import {
  IconButton,
  Grid,
  Stack,
  Button,
  Box,
  Checkbox,
  Tabs,
  Tab,
  Link,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  FormControl,
  FormLabel,
  OutlinedInput,
  FormHelperText,
  FormControlLabel,
  MenuItem,
  Select,
} from "@mui/material";
import styles from "./index.module.scss";
import classnames from "classnames";
import coursesApi from "../../../services/school/courses";
import usersApi from "../../../services/school/users";
import examPackageApi from "../../../services/school/examPackage";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Icon from "@mdi/react";
import {
  mdiTextBox,
  mdiPencil,
  mdiTrashCanOutline,
  mdiPlusCircle,
  mdiArrowLeft
} from "@mdi/js";
import UserSendMailDialog from "../../../components/Common/UserSendMailDialog";
//import UserAddFormDialog from "../UserProfileFormDialog";
import ConfirmedDialog from "../../../components/Common/ConfirmedDialog";
import { useAuth } from "../../../hooks/auth";
import { PermissionLevel } from "../../../constants/PermissionLevel";
import TabPanel from "../../../components/Common/TabPanel";
import { useUserUtils } from "../../../hooks/userUtils";
import UserProfileFormDialog from "../../../components/Common/UserProfileFormDialog";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CourseRecordSummary from "../../../components/Common/CourseRecordSummary";
import recordsApi from "../../../services/school/records";
import { useTimeUtils } from "../../../hooks/timeUtils";
import { useTranslation } from "react-i18next";

const StudentList = (props) => {
  const { t } = useTranslation();
  const [studentList, setStudentList] = useState([]);
  const [suggestUsers, setSuggestUsers] = useState();
  const [removeConfirmDialog, setRemoveConfirmDialog] = useState({
    open: false,
    targets: [],
  });
  const profileDialogInit = {
    open: false,
    userData: null,
    initMode: "check",
  };
  const [profileDialog, setProfileDialog] = useState(profileDialogInit);
  const params = useParams();
  const { displayRoles, displayStatus } = useUserUtils();
  const navigate = useNavigate();
  const fetchCourseStudents = async () => {
    await coursesApi
      .getCourseStudnets(params.enterpriseId, params.courseId)
      .then((res) => {
        setStudentList(
          res.data
            .sort((a, b) =>
              a.enterpriseUserId.localeCompare(b.enterpriseUserId)
            )
            .map((item) => ({
              ...item,
              checked: false,
            }))
        );
      });
  };
  useEffect(() => {
    fetchCourseStudents();
  }, []);

  const handleSelected = (event, targetItem) => {
    let newList = studentList.map((item) => {
      if (item.id === targetItem.id) {
        return { ...item, checked: event.target.checked };
      }
      return item;
    });
    setStudentList(newList);
  };

  const onBatchRemoveBtnClcik = () => {
    const targets = studentList.filter((p) => p.checked);
    setRemoveConfirmDialog({
      ...removeConfirmDialog,
      open: true,
      targets: targets,
    });
  };

  const onRemoveCancleClick = () => {
    setRemoveConfirmDialog({
      ...removeConfirmDialog,
      open: false,
      targets: [],
    });
  };

  const onRemoveConfirmedClick = async () => {
    let targets = removeConfirmDialog.targets.map((p) => p.id);
    await coursesApi
      .removeCourseStudnets(params.enterpriseId, params.courseId, targets)
      .then((res) => {
        fetchCourseStudents();
        setRemoveConfirmDialog({
          ...removeConfirmDialog,
          open: false,
          targets: [],
        });
      });
  };
  const onProfileDialogClose = async (queryUser) => {
    if (queryUser) {
      await coursesApi
        .addCourseStudent(params.enterpriseId, params.courseId, queryUser.id)
        .then((res) => {
          fetchCourseStudents();
        })
        .catch((err) => {
          alert(t("ManageCourse.StudentList_alert_error"));
        });
    }
    setProfileDialog(profileDialogInit);
  };

  const handerAddOnClick = async () => {
    setProfileDialog({ open: true });
    await usersApi
      .getUsers(params.enterpriseId, null, ["Student"])
      .then((res) => {
        setSuggestUsers(res.data);
      });
  };

  return (
    <>
      <Grid item className={styles.card} container>
        <Grid
          container
          item
          alignItems="start"
          direction="row"
          justifyContent="flex-end"
          gap={1}
        >
          <Button
            variant="outlined"
            disabled={!studentList?.some((p) => p.checked)}
            onClick={() => onBatchRemoveBtnClcik()}
          >
            {t("ManageCourse.StudentList_button_delete")}
          </Button>
          <Button variant="contained" onClick={() => handerAddOnClick()}>
            {t("ManageCourse.StudentList_button_add")}
          </Button>
        </Grid>
        <div>
          <table className={styles.table} cellSpacing="0">
            <thead>
              <tr>
                <th width="40"></th>
                <th>{t("ManageCourse.StudentList_table_account")}</th>
                <th>{t("ManageCourse.StudentList_table_name")}</th>
                <th>{t("ManageCourse.StudentList_table_status")}</th>
                <th style={{ paddingLeft: "10px" }}>
                  {t("ManageCourse.StudentList_table_function")}
                </th>
              </tr>
            </thead>
            <tbody>
              {studentList.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <Checkbox
                        checked={item.checked}
                        onChange={(event) => handleSelected(event, item)}
                      />
                    </td>
                    <td data-label={`${t("ManageCourse.StudentList_table_account")}:`}>
                      {item.enterpriseUserId}
                    </td>
                    <td data-label={`${t("ManageCourse.StudentList_table_name")}:`}>
                      {item.fullName}
                    </td>
                    <td data-label={`${t("ManageCourse.StudentList_table_status")}:`}>
                      {displayStatus(item)}
                    </td>
                    <td>
                      <IconButton
                        className={styles.btn_edit}
                        aria-label="delete"
                        onClick={() =>
                          setProfileDialog({ open: true, userData: item })
                        }
                      >
                        <Icon path={mdiPencil} size={1} />
                      </IconButton>
                      <IconButton
                        className={styles.btn_del}
                        aria-label="delete"
                        onClick={() =>
                          setRemoveConfirmDialog({
                            ...removeConfirmDialog,
                            open: true,
                            targets: [item],
                          })
                        }
                      >
                        <Icon path={mdiTrashCanOutline} size={1} />
                      </IconButton>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Grid>
      <ConfirmedDialog
        open={removeConfirmDialog.open}
        title={t("ManageCourse.StudentList_deleteDialog_title")}
        onCancleClick={onRemoveCancleClick}
        onConfirmedClcik={onRemoveConfirmedClick}
      >
        <>
          {removeConfirmDialog.targets?.length > 0 && (
            <div>
              {removeConfirmDialog.targets?.length > 1 ? (
                <>{t("ManageCourse.StudentList_deleteDialog_removeStudent", { var: removeConfirmDialog?.targets.length })}</>
              ) : (
                <>
                  {t("ManageCourse.StudentList_deleteDialog_text")}
                  <strong>
                    {removeConfirmDialog?.targets[0]?.enterpriseUserId}
                  </strong>
                  ？
                </>
              )}
            </div>
          )}
        </>
      </ConfirmedDialog>
      <UserProfileFormDialog
        open={profileDialog.open}
        userData={profileDialog.userData}
        onClose={onProfileDialogClose}
        suggestUsers={suggestUsers}
        disableRole={true}
        allowConfilct={true}
      />
    </>
  );
};

const PackageList = (props) => {
  const { t } = useTranslation();
  const [packageList, setPackageList] = useState([]);
  const [removeConfirmDialog, setRemoveConfirmDialog] = useState({
    open: false,
    targets: [],
  });
  const params = useParams();
  const fetchCourseContents = async () => {
    await coursesApi
      .getCourseContent(params.enterpriseId, params.courseId)
      .then((res) => {
        setPackageList(
          res.data.map((item) => ({
            ...item,
            checked: false,
          }))
        );
      });
  };
  useEffect(() => {
    fetchCourseContents();
  }, []);

  return (
    <>
      <Grid item className={styles.card} container>
        <Grid
          container
          item
          alignItems="start"
          direction="row"
          justifyContent="flex-end"
          gap={1}
        >
          <PackagePatchFormDialog
            btnText={t("ManageCourse.ClassList_button_edit")}
            dialogTitle={t("ManageCourse.ClassList_editDialog_title")}
            fetchData={fetchCourseContents}
            existPackages={packageList}
          />
        </Grid>
        <div>
          <table className={styles.table} cellSpacing="0">
            <thead>
              <tr>
                <th></th>
                <th>{t("ManageCourse.ClassList_table_name")}</th>
                <th>{t("ManageCourse.ClassList_table_content")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {packageList.map((item) => {
                return (
                  <tr key={item.id}>
                    <td></td>
                    <td data-label={`${t("ManageCourse.ClassList_table_name")}:`}>
                      {item.name}
                    </td>
                    <td data-label={`${t("ManageCourse.ClassList_table_content")}:`}>
                      {item.contentProvider}
                    </td>
                    <td></td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Grid>
    </>
  );
};

const PackagePatchFormDialog = ({
  btnText,
  dialogTitle,
  fetchData,
  existPackages,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [packageList, setPackageList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const userStore = useSelector((state) => state.user);
  const param = useParams();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setPackageList([]);
  };

  const handleSendClick = async () => {
    let requireList = packageList.filter((p) => p.checked).map((p) => p.id);

    await coursesApi
      .patchCourseContent(param.enterpriseId, param.courseId, requireList)
      .then((res) => {
        fetchData();
        handleClose();
      })
      .catch((err) => {
        alert(t("ManageCourse.ClassList_alert_error"));
      });
  };

  useEffect(() => {
    if (!open) return;
    fetchPackages();
  }, [open]);

  const fetchPackages = async () => {
    await examPackageApi.getExamPackages(param.enterpriseId).then((res) => {
      let newData = res.data.map((item) => ({
        ...item,
        checked: existPackages?.some((a) => a.id === item.id),
      }));
      setPackageList(newData);
    });
  };

  const handlePackageSelected = (event, examPackage) => {
    let newPackages = packageList.map((item) => {
      if (item.id === examPackage.id) {
        return { ...item, checked: event.target.checked };
      }
      return item;
    });
    setPackageList(newPackages);
  };

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen}>
        {btnText}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent dividers>
          <table className={styles.table} cellSpacing="0">
            <thead>
              <tr>
                <th width="40"></th>
                <th>{t("ManageCourse.ClassList_dialog_table_name")}</th>
                <th>{t("ManageCourse.ClassList_dialog_table_content")}</th>
                <th>{t("ManageCourse.ClassList_dialog_table_explanation")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {packageList.map((item) => {
                return (
                  <tr key={`${item.id}`}>
                    <td>
                      <Checkbox
                        checked={item.checked}
                        onChange={(event) => handlePackageSelected(event, item)}
                      />
                    </td>
                    <td
                      data-label={`${t(
                        "ManageCourse.ClassList_dialog_table_name"
                      )}:`}
                    >
                      {item.name}
                    </td>
                    <td
                      data-label={`${t(
                        "ManageCourse.ClassList_dialog_table_content"
                      )}:`}
                    >
                      {item.contentProvider}
                    </td>
                    <td
                      data-label={`${t(
                        "ManageCourse.ClassList_dialog_table_explanation"
                      )}:`}
                    >
                      {item.enableExplain
                        ? t("ManageCourse.ClassList_dialog_explanation_yes")
                        : t("ManageCourse.ClassList_dialog_explanation_no")}
                    </td>
                    <td></td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            {t("ManageCourse.ClassList_dialog_button_cancel")}
          </Button>
          <Button variant="contained" onClick={handleSendClick}>
            {t("ManageCourse.ClassList_dialog_button_submit")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const CourseEditFormDialog = ({ open, onClose, courseData }) => {
  const { t } = useTranslation();
  const [courseName, setCourseName] = useState("");
  const [startTime, setStartTime] = useState();
  const [expireTime, setExpireTime] = useState();
  const [enabled, setEnabled] = useState(true);
  const initHelpText = {
    courseName: "",
    startTime: "",
    expireTime: "",
  };
  const [helperText, setHelperText] = useState(initHelpText);
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const param = useParams();

  const handleClose = () => {
    onClose(false);
  };

  const verifyInputData = () => {
    let isOK = true;
    let tempHelperText = { ...helperText };
    if (courseName === null || courseName.trim() === "") {
      tempHelperText = {
        ...tempHelperText,
        courseName: t("ManageCourse.CourseEdit_dialog_helperText_courseName"),
      };
      isOK = false;
    }
    if (isOK) {
      setHelperText(initHelpText);
    } else {
      setHelperText(tempHelperText);
    }

    return isOK;
  };

  const handleSendClick = async () => {
    if (!verifyInputData()) return;

    let patchData = {
      courseName: courseName,
      startTime: startTime,
      expireTime: expireTime,
      isEnabled: enabled,
    };
    await coursesApi
      .patchCourse(param.enterpriseId, param.courseId, patchData)
      .then((res) => {
        onClose(true);
      })
      .catch((err) => {
        alert(
          t("ManageCourse.CourseEdit_dialog_alert_error") +
          err.response.data.Message
        );
      });
  };

  useEffect(() => {
    setHelperText({ ...helperText, courseName: "" });
  }, [courseName]);

  useEffect(() => {
    if (!courseData) return;
    if (courseData?.startTime) setStartTime(new Date(courseData.startTime));
    if (courseData?.expireTime) setExpireTime(new Date(courseData.expireTime));
    setCourseName(courseData.name);
    setEnabled(courseData.status === "Publish");
  }, [courseData]);
  const onStartTimeChange = (date) => {
    if (date) setStartTime(new Date(new Date(date).setHours(0, 0, 0)));
    else setStartTime();
  };

  const onExpireTimeChange = (date) => {
    if (date) setExpireTime(new Date(new Date(date).setHours(23, 59, 59)));
    else setExpireTime();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{t("ManageCourse.CourseEdit_dialog_title")}</DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <FormControl>
                <FormLabel>{t("ManageCourse.CourseEdit_dialog_label_name")}</FormLabel>
                <OutlinedInput
                  margin="dense"
                  type="text"
                  fullWidth
                  value={courseName}
                  placeholder={t("ManageCourse.CourseEdit_dialog_placeholder_name")}
                  size="small"
                  onChange={(e) => setCourseName(e.target.value)}
                  variant="outlined"
                />
                <FormHelperText error={helperText.courseName != ""}>
                  {helperText.courseName}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item container direction="row">
              <FormControl>
                <FormLabel>{t("ManageCourse.CourseEdit_dialog_label_validity")}</FormLabel>
                <FormControl>
                  <DatePicker
                    className={styles.datepicker}
                    selected={startTime}
                    popperPlacement="right-end"
                    onChange={(date) => onStartTimeChange(date)}
                    placeholderText={t(
                      "ManageCourse.CourseEdit_dialog_placeholder_startTime"
                    )}
                    dateFormat="yyyy/MM/dd"
                    customInput={
                      <OutlinedInput
                        size="small"
                        sx={{ m: 1, minWidth: 120 }}
                      />
                    }
                    withPortal={windowSize.current[0] < 600}
                  ></DatePicker>
                </FormControl>
                <FormControl>
                  <FormLabel className={styles.datepickerTo}>
                    {t("ManageCourse.CourseEdit_dialog_label_to")}
                  </FormLabel>
                  <DatePicker
                    className={styles.datepicker}
                    selected={expireTime}
                    popperPlacement="right-end"
                    onChange={(date) => onExpireTimeChange(date)}
                    placeholderText={t(
                      "ManageCourse.CourseEdit_dialog_placeholder_expirationTime"
                    )}
                    dateFormat="yyyy/MM/dd"
                    customInput={
                      <OutlinedInput
                        size="small"
                        sx={{ m: 1, minWidth: 120 }}
                      />
                    }
                    withPortal={windowSize.current[0] < 600}
                  ></DatePicker>
                </FormControl>
                <FormHelperText>
                  {t("ManageCourse.CourseEdit_dialog_helperText")}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={enabled}
                      onChange={(e) => setEnabled(e.target.checked)}
                    />
                  }
                  label={t("ManageCourse.CourseEdit_dialog_checkbox_label")}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            {t("ManageCourse.CourseEdit_dialog_button_cancel")}
          </Button>
          <Button variant="contained" onClick={handleSendClick}>
            {t("ManageCourse.CourseEdit_dialog_button_confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const CourseRecordList = (props) => {
  const { t } = useTranslation();
  const [studentList, setStudentList] = useState([]);
  const [recordList, setRecordList] = useState([]);
  const [dsiaplyList, setDisplayList] = useState([]);
  const [orderBy, setOrderBy] = useState("member_asc");
  const [examPackageList, setExamPackages] = useState([]);
  const [selectExamPacakge, setSelectExamPacakge] = useState(null);
  const params = useParams();
  const { courseId } = props;
  const { toHHMMSS } = useTimeUtils();
  const navigate = useNavigate();


  const ToPercent = (num) => {
    if (!num) return "";
    return Number(Math.round(num * 100).toFixed(2)) + "%";
  };

  const fetchCourseStudents = async () => {
    await coursesApi
      .getCourseStudnets(params.enterpriseId, params.courseId)
      .then((res) => {
        setStudentList(
          res.data.map((item) => ({
            ...item,
            checked: false,
          }))
        );
      });
  };

  const fetchCoursePackages = async () => {
    const searchText = "";
    await coursesApi
      .getCourseContent(params.enterpriseId, courseId, searchText)
      .then((res) => {
        setExamPackages(res.data);
      });
  };

  const fetchRecord = async () => {
    await recordsApi
      .getRanking(params.enterpriseId, params.courseId, selectExamPacakge?.id)
      .then((res) => {
        setRecordList(res.data);
      });
  };

  const onExamPackageSelectedChanged = (event) => {
    let selectExamPacakge = examPackageList.find(
      (p) => p.id == event.target.value
    );
    setSelectExamPacakge(selectExamPacakge);
  };

  useEffect(() => {
    if (!studentList) return;

    const dsiaplyList = studentList.map((item) => {
      const itemRecord = recordList.find(
        (p) => p.enterpriseUserName === item.enterpriseUserId
      );
      return {
        ...item,
        ...itemRecord,
      };
    });
    switch (orderBy) {
      case "member_asc":
        dsiaplyList.sort((a, b) =>
          a.enterpriseUserId.localeCompare(b.enterpriseUserId)
        );
        break;
      case "member_desc":
        dsiaplyList.sort((a, b) =>
          b.enterpriseUserId.localeCompare(a.enterpriseUserId)
        );
        break;
      case "learninigTime_asc":
        dsiaplyList.sort((a, b) =>
          a.traningSecond ? a.traningSecond - b.traningSecond : -1
        );
        break;
      case "learninigTime_desc":
        dsiaplyList.sort((a, b) =>
          b.traningSecond ? b.traningSecond - a.traningSecond : -1
        );
        break;
      case "mesteryRate_asc":
        dsiaplyList.sort((a, b) =>
          a.masteryRate ? a.masteryRate - b.masteryRate : -1
        );
        break;
      case "mesteryRate_desc":
        dsiaplyList.sort((a, b) =>
          b.masteryRate ? b.masteryRate - a.masteryRate : -1
        );
        break;
      case "taringTimes_asc":
        dsiaplyList.sort((a, b) =>
          a.traningAmount ? a.traningAmount - b.traningAmount : -1
        );
        break;
      case "taringTimes_desc":
        dsiaplyList.sort((a, b) =>
          b.traningAmount ? b.traningAmount - a.traningAmount : -1
        );
        break;
      case "springTimes_asc":
        dsiaplyList.sort((a, b) =>
          a.sprintDoneAmount ? a.sprintDoneAmount - b.sprintDoneAmount : -1
        );
        break;
      case "springTimes_desc":
        dsiaplyList.sort((a, b) =>
          b.sprintDoneAmount ? b.sprintDoneAmount - a.sprintDoneAmount : -1
        );
        break;
    }
    setDisplayList(dsiaplyList);

    // .sort((a, b) => {
    //     return b.traningSecond - a.traningSecond;
    // })
  }, [studentList, recordList, orderBy]);

  useEffect(() => {
    fetchRecord();
  }, [selectExamPacakge]);

  useEffect(() => {
    fetchCourseStudents();
    fetchCoursePackages();
  }, []);

  const handlerOrderChange = (key) => {
    if (orderBy.indexOf(key) < 0 || orderBy.includes("asc"))
      setOrderBy(`${key}_desc`);
    else setOrderBy(`${key}_asc`);
  };

  const SortTH = ({ children, orderKey }) => {
    const [isMouseOver, setIsMouseOver] = useState(false);

    return (
      <th>
        <Link
          className={styles.header_action}
          underline="none"
          onClick={() => handlerOrderChange(orderKey)}
          onMouseOver={() => setIsMouseOver(true)}
          onMouseLeave={() => setIsMouseOver(false)}
          style={{ color: "#757575" }}
        >
          {children}&nbsp;
          <span
            className={classnames({
              [styles.hide_action]: orderBy.indexOf(`${orderKey}_`) < 0,
            })}
          >
            {orderBy.includes("asc") ? "▲" : "▼"}
          </span>
          <span
            className={classnames({
              [styles.mouseover_hide_action]:
                orderBy.indexOf(`${orderKey}_`) >= 0 || !isMouseOver,
            })}
          >
            ▼
          </span>
        </Link>
      </th>
    );
  };

  const learningPlan = (userId, userName) => {
    navigate(`springPage`, { state: { selectExamPacakge, examPackageList, userId, userName } });
  }

  return (
    <Grid className={styles.card} container direction="column">
      <Grid item container xs={12} justifyContent={"space-between"}>
        <div>
          <div className={styles.card_title}>
            {t("ManageCourse.CourseRecordList_card_title")}
          </div>
          <div className={styles.card_subtitle}></div>
        </div>
        <Grid item container sx={{ width: "auto" }}>
          <Grid item>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <Select
                value={selectExamPacakge?.id ?? ""}
                onChange={onExamPackageSelectedChanged}
                displayEmpty
              >
                <MenuItem value="">{t("ManageCourse.CourseRecordList_item")}</MenuItem>
                {examPackageList.map((item) => {
                  return (
                    <MenuItem key={`package_${item.id}`} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <div>
        <table className={styles.table} cellSpacing={0}>
          <thead>
            <tr>
              <th width="0"></th>
              <SortTH orderKey="member">{t("ManageCourse.CourseRecordList_table_account")}</SortTH>
              <th>{t("ManageCourse.CourseRecordList_table_name")}</th>
              <SortTH orderKey="springTimes">
                計畫完成次數
              </SortTH>
              <SortTH orderKey="learninigTime">
                {t("ManageCourse.CourseRecordList_table_learningTime")}
              </SortTH>
              <SortTH orderKey="mesteryRate">
                {t("ManageCourse.CourseRecordList_table_mastery")}
              </SortTH>
              <th>學習計畫</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {dsiaplyList.map((item, index) => {
              return (
                <tr key={`tr_${index}`}>
                  <td></td>
                  <td
                    data-label={`${t("ManageCourse.CourseRecordList_table_account")}:`}
                  >
                    {item.enterpriseUserId}
                  </td>
                  <td
                    data-label={`${t("ManageCourse.CourseRecordList_table_name")}:`}
                  >
                    {item.fullName}
                  </td>
                  <td
                    data-label={"計畫完成次數"}
                  >
                    {`${item.sprintDoneAmount}/${item.sprintTotalAmount}`}
                  </td>
                  <td
                    data-label={`${t("ManageCourse.CourseRecordList_table_learningTime")}:`}
                  >
                    {toHHMMSS(item.traningSecond)}
                  </td>
                  <td
                    data-label={`${t("ManageCourse.CourseRecordList_table_mastery")}:`}
                  >
                    {ToPercent(item.masteryRate)}
                  </td>
                  <td><Link className={styles.learningPlanBtn} onClick={() => learningPlan(item.userId, item.fullName)}>查看細節</Link></td>
                  <td></td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Grid>
  );
};

const ManageCourse = () => {
  const { t } = useTranslation();
  const [courseInfo, setCourseInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const [tabValue, setTabValue] = useState("namelists");

  const [suggestTeachers, setSuggestTeachers] = useState();
  const [courseEditDialog, setCourseEditDialog] = useState({
    open: false,
  });
  const [removeConfirmDialog, setRemoveConfirmDialog] = useState({
    open: false,
    targets: [],
  });
  const profileDialogInit = {
    open: false,
    userData: null,
    initMode: "query",
  };
  const [profileDialog, setProfileDialog] = useState(profileDialogInit);
  const params = useParams();
  const authStore = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCourseInfo();
  }, []);

  const fetchCourseInfo = async () => {
    await coursesApi
      .getCourseInfo(params.enterpriseId, params.courseId)
      .then((res) => {
        setCourseInfo(res.data);
      });
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleTeacherDelete = (target) => {
    const targets = [target];
    setRemoveConfirmDialog({
      ...removeConfirmDialog,
      open: true,
      targets: targets,
    });
  };

  const handleAddTeacherClick = async (event) => {
    setProfileDialog({ open: true, initMode: "query" });

    await usersApi
      .getUsers(params.enterpriseId, null, ["Teacher"])
      .then((res) => {
        setSuggestTeachers(res.data);
      });
  };

  const onRemoveCancleClick = () => {
    setRemoveConfirmDialog({
      ...removeConfirmDialog,
      open: false,
      targets: [],
    });
  };

  const onRemoveConfirmedClick = async () => {
    await coursesApi
      .removeCourseStaff(
        params.enterpriseId,
        params.courseId,
        removeConfirmDialog.targets[0].id
      )
      .then((res) => {
        fetchCourseInfo();
        setRemoveConfirmDialog({
          ...removeConfirmDialog,
          open: false,
          targets: [],
        });
      });
  };
  const onProfileDialogClose = async (queryUser) => {
    if (queryUser && queryUser.isExist) {
      await coursesApi
        .addCourseStaff(params.enterpriseId, params.courseId, queryUser.id)
        .then((res) => {
          fetchCourseInfo();
        });
    }
    setProfileDialog(profileDialogInit);
  };

  const onCourseEditDialogClose = async (isFetch) => {
    if (isFetch) fetchCourseInfo();
    setCourseEditDialog({ open: false });
  };

  const handleReturn = () => {
    navigate(-2);
  }

  return (
    <>
      <Box>
        <Box className={styles.returnBar} >
          <IconButton onClick={handleReturn}>
            <Icon path={mdiArrowLeft} size={1} color={"#5BB6FF"} />
          </IconButton>
          <div className={styles.returnText} onClick={handleReturn}>
            返回
          </div>
        </Box>
        <Box>
          <div className={styles.card}>
            <div className={styles.card_title}>{courseInfo?.name}</div>
            <div className={styles.card_info}>
              <div className={styles.card_info_left}></div>
              <div className={styles.card_info_right}>
                <div>
                  {t("ManageCourse.CourseEdit_startTime")}
                  {courseInfo?.startTime
                    ? new Date(courseInfo?.startTime).toLocaleDateString()
                    : "-"}
                </div>
                <div>
                  {t("ManageCourse.CourseEdit_endTime")}
                  {courseInfo?.expireTime
                    ? new Date(courseInfo?.expireTime).toLocaleDateString()
                    : "-"}
                </div>
              </div>
              {authStore.permissionLevel >= PermissionLevel.EnterpriseAdmin && (
                <IconButton
                  className={styles.btn_card_edit}
                  onClick={() => setCourseEditDialog({ open: true })}
                >
                  <Icon path={mdiPencil} size={0.8} />
                </IconButton>
              )}
            </div>
          </div>
          <div className={styles.card}>
            <Stack direction="row" spacing={1}>
              <div className={styles.card_title}>
                {t("ManageCourse.Teacher_card_title")}
              </div>
              {courseInfo?.teachers?.map((item) => {
                return (
                  <Chip
                    key={item.id}
                    label={item.fullName}
                    variant="outlined"
                    onDelete={
                      authStore.permissionLevel >=
                        PermissionLevel.EnterpriseAdmin
                        ? () => handleTeacherDelete(item)
                        : null
                    }
                  />
                );
              })}
            </Stack>
            {authStore.permissionLevel >= PermissionLevel.EnterpriseAdmin && (
              <IconButton
                className={styles.btn_card_edit}
                onClick={handleAddTeacherClick}
              >
                <Icon path={mdiPlusCircle} size={0.8} />
              </IconButton>
            )}
          </div>
        </Box>
        <Box>
          <div className={styles.card}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={tabValue}
                variant="scrollable"
                onChange={handleTabChange}
              >
                <Tab
                  classes={{ root: styles.TabColor }}
                  label={t("ManageCourse.Tab_student")}
                  value="namelists"
                />
                <Tab
                  classes={{ root: styles.TabColor }}
                  label={t("ManageCourse.Tab_course")}
                  value="textbooks"
                />
                <Tab
                  classes={{ root: styles.TabColor }}
                  label={t("ManageCourse.Tab_summary")}
                  value="summary"
                />
                <Tab
                  classes={{ root: styles.TabColor }}
                  label={t("ManageCourse.Tab_records")}
                  value="rank"
                />
              </Tabs>
            </Box>
            <TabPanel value={tabValue} index="namelists">
              <StudentList></StudentList>
            </TabPanel>
            <TabPanel value={tabValue} index="textbooks">
              <PackageList></PackageList>
            </TabPanel>
            <TabPanel value={tabValue} index="summary">
              <CourseRecordSummary
                courseId={params.courseId}
              ></CourseRecordSummary>
            </TabPanel>
            <TabPanel value={tabValue} index="rank">
              <CourseRecordList courseId={params.courseId}></CourseRecordList>
            </TabPanel>
          </div>
        </Box>
      </Box>
      <UserProfileFormDialog
        open={profileDialog.open}
        userData={profileDialog.userData}
        onClose={onProfileDialogClose}
        suggestUsers={suggestTeachers}
        allowConfilct={true}
      />
      <ConfirmedDialog
        open={removeConfirmDialog.open}
        title={t("ManageCourse.Teacher_card_dialog_title")}
        onCancleClick={onRemoveCancleClick}
        onConfirmedClcik={onRemoveConfirmedClick}
      >
        <>
          {t("ManageCourse.Teacher_card_dialog_text")}
          <strong>{removeConfirmDialog?.targets[0]?.fullName}</strong>？
        </>
      </ConfirmedDialog>
      <CourseEditFormDialog
        open={courseEditDialog.open}
        courseData={courseInfo}
        onClose={onCourseEditDialogClose}
      />

    </>
  );

};

export default ManageCourse;
