import { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Button,
  Container,
  Typography,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  SvgIcon,
} from "@mui/material";
import styles from "./index.module.scss";
import logo from "../../../assets/icon_footer_logo_3x.png";
import { useAuth } from "../../../hooks/auth";
import { useSelector } from "react-redux";
import enterpriseAdminApi from "../../../services/admin/enterprise";
import schoolApi from "../../../services/school/enterpriseInfo";
import { useNavigate, useParams } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiMenu } from "@mdi/js";
import { mdiEarth } from '@mdi/js';
import auth from "../../../services/general/auth";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../LanguageSelector";



// const loggedin = [t("Topbar.LogOut")];

const Topbar = (props) => {
  const { t } = useTranslation();
  const auth = useAuth();
  // const params = useParams();
  const navigate = useNavigate();
  // const [searchText, setSearchText] = useState("");
  const userInfo = useSelector((state) => state.user);

  const onLogoClick = () => {
    window.location = `/`;
  };

  return (
    <AppBar position="fixed" sx={{ zIndex: 1201 }}>
      <Container
        maxWidth={false}
        sx={{ background: "white", zIndex: 1201 }}
        classes={{ root: styles.TopbarContainer }}
      >
        <Toolbar className={styles.toolbar} disableGutters>
          <Box className={styles.toolbar_left}>
            <IconButton
              className={styles.btn_menu_icon}
              onClick={props.handleDrawerOpen}
              sx={{ display: { xs: "block", sm: "none" } }}
            >
              <Icon className={styles.menu_icon} path={mdiMenu} />
            </IconButton>
            <div className={styles.logoWrap} onClick={() => onLogoClick()}>
              <div>
                <img alt="TAG EASY" src={logo} className={styles.logo} />
              </div>
              <h4 className={styles.platform_name}>
                <span className={styles.logo_txt}>{t("Topbar.LogoTxt")}</span>
                <span>{t("Topbar.Title")}</span>
              </h4>
            </div>
          </Box>
          <Box className={styles.toolbar_right} >
            <LanguageSelector
              styles={{
                display: { xs: "none", sm: "block" },
                '.MuiOutlinedInput-input': {
                  padding: '8px 30px 8px 13px !important',
                  fontSize: '0.8rem'
                },
                '.MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '.css-1aohbzm-MuiSvgIcon-root': {
                  fontSize: '1.3rem'
                },
              }}
            />
            <Button
              className={styles.btn_logout}
              variant="contained"
              sx={{ display: { xs: "none", sm: "block" } }}
              onClick={() => auth.logout()}
            >
              {t("Topbar.logoutBtn")}
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Topbar;
