import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { ThemeProvider, createTheme } from "@mui/material";
import ProtectedRoute from "./ProtectRoute";
import { AuthProvider } from "./hooks/auth";
import { Provider } from "react-redux";
import store from "./store";
import SchoolRoute from './routes/SchoolRoute';
import AdminRoute from './routes/AdminRoute';
import EnterpriseRoute from './routes/EnterpriseRoute';
import LoginPage from './pages/Common/LoginPage';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';
import i18nBackend from 'i18next-http-backend';
import { initApi } from "./services/api";
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import styles from "./App.module.scss";

const queryClient = new QueryClient();


const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Noto Sans TC'
    },
  },
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      light: '#0066ff',
      main: '#0044ff',
      contrastText: '#ffcc00',
    },
    success: {
      main: '#62af00'
    },
    error: {
      main: '#D50000'
    }
  }
});

i18n
  .use(i18nBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'zh-tw',
    debug: (process.env.REACT_APP_ENV_NAME !== "release"),
    interpolation: {
      escapeValue: false,
    }
  });


function App() {


  useEffect(() => {
    initApi(store.dispatch);
  }, [store.dispatch]);

  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <AuthProvider>
              {process.env.REACT_APP_ENV_NAME === "dev" &&
                <>
                  <div className={styles.dev_banner_topLeft}>DEV</div>
                  <div className={styles.dev_banner_bottomLeft}>DEV</div>
                  <div className={styles.dev_banner_topRight}>DEV</div>
                  <div className={styles.dev_banner_bottomRight}>DEV</div>
                </>
              }
              <Routes>
                <Route path="/login" element={<LoginPage />}></Route>
                <Route path="" element={<ProtectedRoute />}>
                  <Route path="/admin/:enterpriseId/*" element={<AdminRoute />} />
                  <Route path="/school/:enterpriseId/*" element={<SchoolRoute />} />
                  <Route path="/enterprise/:enterpriseId/*" element={<EnterpriseRoute />} />
                </Route>
              </Routes>
            </AuthProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </I18nextProvider>
    </Provider >
  );
}

export default App;
