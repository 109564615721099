import { useState, useEffect, useRef } from "react";
import {
  Box,
  Grid,
  Checkbox,
  Stepper,
  Button,
  Step,
  StepLabel,
  Radio,
  FormHelperText,
  DialogActions,
  FormControl,
  FormLabel,
  CircularProgress,
  LinearProgress,
  FormControlLabel,
  Select,
  MenuItem,
  RadioGroup,
  TextField,
  Stack,
} from "@mui/material";
import Icon from "@mdi/react";
import { mdiPencil, mdiTrashCanOutline, mdiTextBox } from "@mdi/js";
import styles from "./index.module.scss";
import coursesApi from "../../../services/school/courses";
import usersApi from "../../../services/school/users";
import taskApi from "../../../services/school/tasks";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import ExcelJS from "exceljs";
import { useTimeUtils } from "../../../hooks/timeUtils";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

const readXlsxText = (rowdata) => {
  if (rowdata?.text) return rowdata?.text;
  if (rowdata?.richText) return rowdata.richText.map((p) => p.text).join("");

  return rowdata;
};
const StudentImport = ({ onBackClick }) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [importDatas, setImportDatas] = useState([]);
  const [courseList, setCourseList] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [override, setOverride] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [checkResult, setCheckResult] = useState({
    isDone: false,
    errorAmount: 0,
  });
  const [uploadProgress, setUploadProgress] = useState({
    isDone: false,
    porgress: 0,
  });
  const [allowNextStep, setAllowNextStep] = useState(false);
  const steps = [
    t("StudentImport.Steps_select"),
    t("StudentImport.Steps_verification"),
    t("StudentImport.Steps_Delivery"),
  ];
  const params = useParams();
  const { validateDate } = useTimeUtils();

  const fetchCourses = async () => {
    await coursesApi.getCourses(params.enterpriseId).then((res) => {
      setCourseList(res.data);
    });
  };

  const readXlsxFile = () => {
    return new Promise((resolve, reject) => {
      const importDatas = [];
      const wb = new ExcelJS.Workbook();

      const reader = new FileReader();
      reader.readAsArrayBuffer(selectedFile);
      reader.onload = () => {
        const buffer = reader.result;
        wb.xlsx.load(buffer).then((workbook) => {
          workbook.eachSheet((sheet, id) => {
            sheet.eachRow((row, rowIndex) => {
              if (rowIndex == 1) {
                checkHeader(row);
                return;
              }
              let courseName = readXlsxText(row.values[1])?.toString();
              let studentUserId = readXlsxText(row.values[2])?.toString();
              let password = `${readXlsxText(row.values[3])?.toString()}`;
              let fullname = readXlsxText(row.values[4])?.toString();
              let email =
                row.values.length > 4 ? readXlsxText(row.values[5])?.toString() : null;
              let expireTime =
                row.values.length > 5 ? readXlsxText(row.values[6]) : null;
              console.log(row.values);
              if (validateDate(expireTime))
                expireTime = new Date(expireTime).toLocaleDateString();

              if (courseName && studentUserId) {
                importDatas.push({
                  errors: [],
                  courseName: courseName?.trim(),
                  studentUserId: studentUserId?.trim(),
                  password: password?.trim(),
                  fullname: fullname?.trim(),
                  email: email?.trim(),
                  expireTime: expireTime,
                });
              }
            });
          });
          resolve(importDatas);
        });
      };
    });
  };
  const checkHeader = (row) => {
    const headers = [
      t("StudentImport.CheckHeader_className"),
      t("StudentImport.CheckHeader_account"),
      t("StudentImport.CheckHeader_password"),
      t("StudentImport.CheckHeader_name"),
    ];
    if (headers.some((p, index) => row.values[index + 1] !== p)) {
      setHelperText(t("StudentImport.CheckHeader_helperText"));
      throw new Error("Verify Header Error!");
    }
  };

  const handleStep = async () => {
    if (activeStep === 0) {
      const importDatas = await readXlsxFile();
      setImportDatas(importDatas);
    } else if (activeStep === steps.length - 1) {
      onBackClick();
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 0 || activeStep === steps.length - 1) {
      onBackClick();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  // const fetchUsers = async () => {
  //     await usersApi.getUsers(params.enterpriseId, null, ["Teacher"])
  //         .then(res => {
  //             setTeacherList(res.data);
  //         })
  // }

  const checkData = () => {
    importDatas.forEach((item) => {
      let errors = [];
      if (!item.courseName || item.courseName.length === 0)
        errors.push(t("StudentImport.ErrorMsg_className"));

      if (courseList.filter((p) => p.name === item.courseName).length == 0)
        errors.push(t("StudentImport.ErrorMsg_notCreated"));

      if (!item.password || item.password.length < 8)
        errors.push(t("StudentImport.ErrorMsg_passwordFormat"));

      if (item.expireTime && !validateDate(item.expireTime))
        errors.push(t("StudentImport.ErrorMsg_expirationTime"));

      if (errors.length > 0) {
        item.errors = errors;
      }
    });
    setCheckResult({
      isDone: true,
      errorAmount: importDatas.filter((p) => p.errors.length > 0).length,
    });
  };

  const onUploadProgress = ({ loaded, total }) => {
    let percent = Math.floor((loaded * 100) / total);
    setUploadProgress({
      isDone: false,
      porgress: percent,
    });
  };

  const submitTask = async () => {
    setUploadProgress({
      isDone: false,
      porgress: 0,
    });
    const taskParams = {
      override: override,
      timeoffset: new Date().getTimezoneOffset(),
    };
    await taskApi
      .addTask(
        params.enterpriseId,
        selectedFile,
        "ImportStudents",
        taskParams,
        onUploadProgress
      )
      .then((res) => {
        setUploadProgress({
          isDone: true,
          porgress: 100,
        });
      });
  };

  useEffect(() => {
    if (!courseList) return;
    checkData();
  }, [courseList]);

  useEffect(() => {
    switch (activeStep) {
      case 0:
        setCheckResult({
          isDone: false,
          errorAmount: 0,
        });
        break;
      case 1:
        fetchCourses();
        break;
      case 2:
        submitTask();
        break;
    }
  }, [activeStep]);

  useEffect(() => {
    setAllowNextStep(false);
    if (activeStep === 0 && selectedFile) setAllowNextStep(true);
    if (activeStep === 1 && checkResult.isDone && checkResult.errorAmount === 0)
      setAllowNextStep(true);
    if (activeStep === 2) setAllowNextStep(true);
  }, [activeStep, selectedFile, checkResult]);

  return (
    <Grid container item className={styles.card}>
      <Grid item>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Grid>
      <Grid item className={styles.card}>
        <Box>
          <Grid container direction="column">
            {activeStep === 0 && (
              <>
                <Grid
                  item
                  container
                  direction="row"
                  className={styles.gard_card}
                >
                  <div className={styles.gard_card_icon}>
                    {t("StudentImport.List_title")}
                  </div>
                  <div className={styles.gard_card_list}>
                    <ul className={styles.card_subtitle}>
                      {/* <li>系統中班級名稱為唯一值，不可重複</li>
                                        <li>班級教師帳號須事先匯入系統</li>
                                        <li>新建班級將立即啟用</li>
                                        <li>更新班級僅能更改啟用時間、結束時間與指派教師，其餘狀態不會變更</li> */}
                      <li>
                        <a href="/student_sample.xlsx">
                          {t("StudentImport.DownloadBtn")}
                        </a>
                      </li>
                    </ul>
                  </div>
                </Grid>
                <Grid item container direction={"column"} gap={2}>
                  <div>{t("StudentImport.SelectExcel_title")}</div>
                  <FormControl>
                    <TextField
                      type="file"
                      inputProps={{
                        accept:
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                      }}
                      onChange={(e) => setSelectedFile(e.target.files[0])}
                    />
                    <FormHelperText error>{helperText}</FormHelperText>
                  </FormControl>
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      {t("StudentImport.CheckBox_title")}
                    </FormLabel>
                    <RadioGroup
                      row
                      value={override}
                      onChange={(e) => setOverride(e.target.value)}
                    >
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label={t("StudentImport.CheckBox_skip")}
                      />
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label={t("StudentImport.CheckBox_override")}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </>
            )}
            {activeStep === 1 && (
              <>
                <Grid item container direction={"column"}>
                  <div>{t("StudentImport.CircularProgress_totalData", { var: importDatas?.length })}</div>
                  <div>
                    {t("StudentImport.CircularProgress_classData")}
                    {!courseList ? (
                      <CircularProgress />
                    ) : (
                      t("StudentImport.CircularProgress_classData_completes")
                    )}
                  </div>
                  {courseList && (
                    <div>
                      {t("StudentImport.CircularProgress_checkData")}
                      {!checkResult.isDone ? (
                        <CircularProgress />
                      ) : (
                        t("StudentImport.CircularProgress_checkData_completes")
                      )}
                    </div>
                  )}
                  {checkResult.isDone && checkResult.errorAmount > 0 && (
                    <>
                      <div>
                        發現{importDatas?.filter((p) => !p.isOK).length}筆錯誤：
                      </div>
                      <div className={styles.table_wrapper}>
                        <table className={styles.table} cellSpacing="0">
                          <thead>
                            <tr>
                              <th></th>
                              <th
                                className={classnames(
                                  styles.sticky_col,
                                  styles.sticky_col_one
                                )}
                              >
                                {" "}
                                {t("StudentImport.verification_table_className")}
                              </th>
                              <th>{t("StudentImport.verification_table_account")}</th>
                              <th>{t("StudentImport.verification_table_password")}</th>
                              <th>{t("StudentImport.verification_table_name")}</th>
                              <th>{t("StudentImport.verification_table_email")}</th>
                              <th>{t("StudentImport.verification_table_expirationTime")}</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {importDatas
                              ?.filter((p) => !p.isOK)
                              .map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td></td>
                                    <td
                                      data-label={`${t("StudentImport.verification_table_className")}:`}
                                      className={classnames(
                                        styles.sticky_col,
                                        styles.sticky_col_one
                                      )}
                                    >
                                      {item.courseName}
                                    </td>
                                    <td
                                      data-label={`${t(
                                        "StudentImport.verification_table_account"
                                      )}:`}
                                    >
                                      <div>{item?.studentUserId} </div>
                                    </td>
                                    <td
                                      data-label={`${t(
                                        "StudentImport.verification_table_password"
                                      )}:`}
                                    >
                                      <div>{item?.password}</div>
                                    </td>
                                    <td
                                      data-label={`${t(
                                        "StudentImport.verification_table_name"
                                      )}:`}
                                    >
                                      <div>{item?.fullname}</div>
                                    </td>
                                    <td
                                      data-label={`${t(
                                        "StudentImport.verification_table_email"
                                      )}:`}
                                    >
                                      <div>{item?.email}</div>
                                    </td>
                                    <td
                                      data-label={`${t(
                                        "StudentImport.verification_table_expirationTime"
                                      )}:`}
                                    >
                                      <div>{item?.expireTime}</div>
                                    </td>
                                    <td>
                                      <ul className={styles.error}>
                                        {item.errors.map((error, index) => {
                                          return (
                                            <li key={`error_${index}`}>
                                              {" "}
                                              {error}
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </td>
                                    <td></td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                  {checkResult.isDone && checkResult.errorAmount === 0 && (
                    <>
                      {checkResult.sameAmount > 0 && (
                        <div>
                          {t("StudentImport.CheckResult_existingData", { var: checkResult.sameAmount })}
                          {override
                            ? t("StudentImport.CheckResult_cover")
                            : t("StudentImport.CheckResult_skip")}
                        </div>
                      )}
                      {t("StudentImport.CheckResult_dataPass")}
                    </>
                  )}
                </Grid>
              </>
            )}
            {activeStep === 2 && (
              <>
                <Box sx={{ width: "100%" }}>
                  <LinearProgress
                    variant="determinate"
                    value={uploadProgress?.porgress ?? 0}
                  />
                </Box>

                <div>
                  {uploadProgress?.isDone
                    ? t("StudentImport.UploadProgress_return")
                    : t("StudentImport.UploadProgress_uploading")}
                </div>
              </>
            )}
          </Grid>
        </Box>
      </Grid>
      <Grid item container justifyContent={"space-between"}>
        <Button color="inherit" onClick={handleBack} sx={{ mr: 1 }}>
          {activeStep === 0 || activeStep === steps.length - 1
            ? t("StudentImport.BackListBtn")
            : t("StudentImport.BackBtn")}
        </Button>
        <Button onClick={handleStep} disabled={!allowNextStep}>
          {activeStep === steps.length - 1
            ? t("StudentImport.ConfirmComplete")
            : t("StudentImport.NextBtn")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default StudentImport;
