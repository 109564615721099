import { useState, useEffect, useRef } from "react";
import {
  Box,
  Grid,
  Checkbox,
  Stepper,
  Button,
  Step,
  StepLabel,
  Radio,
  Tooltip,
  DialogActions,
  FormControl,
  FormLabel,
  CircularProgress,
  LinearProgress,
  FormControlLabel,
  Select,
  MenuItem,
  RadioGroup,
  TextField,
  Stack,
} from "@mui/material";
import Icon from "@mdi/react";
import { mdiPencil, mdiTrashCanOutline, mdiTextBox } from "@mdi/js";
import styles from "./index.module.scss";
import coursesApi from "../../../services/school/courses";
import usersApi from "../../../services/school/users";
import taskApi from "../../../services/school/tasks";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ConfirmedDialog from "../../../components/Common/ConfirmedDialog";
import ExcelJS from "exceljs";
import { useTimeUtils } from "../../../hooks/timeUtils";
import classnames from "classnames";
import CourseImport from "../../../components/Common/CourseImport";
import StudentImport from "../../../components/Common/StudentImport";
import { PermissionLevel } from "../../../constants/PermissionLevel";
import { useTranslation } from "react-i18next";

const TaskTable = () => {
  const { t } = useTranslation();
  const [taskList, setTaskList] = useState([]);
  const [removeConfirmDialog, setRemoveConfirmDialog] = useState({
    open: false,
    targets: [],
  });
  const params = useParams();
  const { toHHMMSS } = useTimeUtils();
  useEffect(() => {
    fetchTasks();
  }, []);

  const fetchTasks = async () => {
    await taskApi.getTasks(params.enterpriseId).then((res) => {
      setTaskList(
        res.data.map((item) => ({
          ...item,
          checked: false,
        }))
      );
    });
  };

  const displayStatus = (item) => {
    switch (item.status) {
      case "Queue":
        return (
          <span style={{ color: "#62AF00" }}>
            {t("ImportTask.Status_return_inQueue")}
          </span>
        );
      case "Process":
        return (
          <span style={{ color: "#ff9900" }}>
            {t("ImportTask.Status_return_processing")}
          </span>
        );
      case "Success":
        return (
          <span style={{ color: "#62AF00" }}>
            {t("ImportTask.Status_return_completed")}
          </span>
        );
      case "Failed":
        return (
          <Tooltip title={item.message} placement="top-end">
            <span style={{ color: "#D00000" }}>
              {t("ImportTask.Status_return_failed")}
            </span>
          </Tooltip>
        );
    }
  };

  const handleSelected = (event, targetItem) => {
    let newList = taskList.map((item) => {
      if (item.id === targetItem.id) {
        return { ...item, checked: event.target.checked };
      }
      return item;
    });
    setTaskList(newList);
  };

  const onBatchRemoveBtnClcik = () => {
    const targets = taskList.filter((p) => p.checked);
    setRemoveConfirmDialog({
      ...removeConfirmDialog,
      open: true,
      targets: targets,
    });
  };

  const onRemoveCancleClick = () => {
    setRemoveConfirmDialog({
      ...removeConfirmDialog,
      open: false,
      targets: [],
    });
  };

  const onRemoveConfirmedClick = async () => {
    let targets = removeConfirmDialog.targets.map((p) => p.id);
    await await taskApi
      .removeTasks(params.enterpriseId, targets)
      .then((res) => {
        fetchTasks();
        setRemoveConfirmDialog({
          ...removeConfirmDialog,
          open: false,
          targets: [],
        });
      });
  };

  const displayType = (item) => {
    switch (item.taskType) {
      case "ImportCourses":
        return t("ImportTask.Type_return_class");
      case "ImportStudents":
        return t("ImportTask.Type_return_student");
    }
    return item.taskType;
  };

  return (
    <Grid item className={styles.card} container>
      <div className={styles.card_title}>{t("ImportTask.Card_title_taskList")}</div>
      <div className={styles.card_subtitle}></div>
      <Grid
        container
        item
        alignItems="start"
        direction="row"
        justifyContent="flex-end"
        gap={1}
      >
        <Button
          variant="outlined"
          disabled={!taskList?.some((p) => p.checked)}
          onClick={() => onBatchRemoveBtnClcik()}
        >
          {t("ImportTask.DeleteBtn")}
        </Button>
      </Grid>
      <div>
        <table className={styles.table} cellSpacing="0">
          <thead>
            <tr>
              <th width="40"></th>
              <th>{t("ImportTask.Table_time")}</th>
              <th>{t("ImportTask.Table_type")}</th>
              <th>{t("ImportTask.Table_file")}</th>
              <th>{t("ImportTask.Table_status")}</th>
            </tr>
          </thead>
          <tbody>
            {taskList
              .sort((a, b) => {
                return new Date(b.createTime) - new Date(a.createTime);
              })
              .map((item) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <Checkbox
                        checked={item.checked}
                        onChange={(event) => handleSelected(event, item)}
                      />
                    </td>
                    <td data-label={`${t("ImportTask.Table_time")}:`}>
                      {new Date(item.createTime).toLocaleString()}
                    </td>
                    <td data-label={`${t("ImportTask.Table_type")}:`}>
                      {displayType(item)}
                    </td>
                    <td data-label={`${t("ImportTask.Table_file")}:`}>
                      {item.inputFile}
                    </td>
                    <td data-label={`${t("ImportTask.Table_status")}`}>
                      {displayStatus(item)}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <ConfirmedDialog
        open={removeConfirmDialog.open}
        title={t("ImportTask.Delete_dialog_title")}
        onCancleClick={onRemoveCancleClick}
        onConfirmedClcik={onRemoveConfirmedClick}
      >
        <>
          {removeConfirmDialog.targets?.length > 0 && (
            <div>{<>{t("ImportTask.Delete_dialog_text")}</>}</div>
          )}
        </>
      </ConfirmedDialog>
    </Grid>
  );
};

const ImportTask = (props) => {
  const { t } = useTranslation();
  const [type, setType] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const authStore = useSelector((state) => state.auth);

  useEffect(() => {
    setType("");
  }, [location.state]);

  const onBackClick = () => {
    setType("");
  };

  return (
    <>
      <Box>
        <div className={styles.title}>{t("ImportTask.Title")}</div>
        <div className={styles.description}></div>
        <Box className={styles.main_content}>
          <Grid item className={styles.card} container>
            <Grid item container alignItems={"center"}>
              <div>{t("ImportTask.Card_title_addTask")}</div>
              <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                <Select
                  value={type}
                  displayEmpty
                  onChange={(e) => setType(e.target.value)}
                >
                  <MenuItem value={""} disabled>
                    <em>{t("ImportTask.Select_item_default")}</em>
                  </MenuItem>
                  {authStore.permissionLevel >=
                    PermissionLevel.EnterpriseAdmin && (
                      <MenuItem value={"ImportCourses"}>
                        {t("ImportTask.Select_item_class")}
                      </MenuItem>
                    )}
                  <MenuItem value={"ImportStudents"}>
                    {t("ImportTask.Select_item_student")}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {type === "" && <TaskTable />}
          {type === "ImportCourses" && (
            <CourseImport onBackClick={onBackClick} />
          )}
          {type === "ImportStudents" && (
            <StudentImport onBackClick={onBackClick} />
          )}
        </Box>
      </Box>
    </>
  );
};

export default ImportTask;
