import { useState, useEffect } from "react";
import styles from "./index.module.scss";
import classnames from "classnames";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Box,
  LinearProgress,
  MenuItem,
  Chip,
  FormControl,
  Grid,
  Popover,
  Select,
  Button,
  Tabs,
  Tab,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import recordsApi from "../../../services/school/records";
import coursesApi from "../../../services/school/courses";
import examPackageApi from "../../../services/school/examPackage";
import TabPanel from "../TabPanel";
import { useTimeUtils } from "../../../hooks/timeUtils";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import Pagination from "../Pagination"

const RankCard = ({ course }) => {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(0);
  const [coursesList, setCoursesList] = useState([]);
  const [examPackageList, setExamPackages] = useState([]);
  const [selectCourse, setSelectCourse] = useState(course);
  const [selectExamPacakge, setSelectExamPacakge] = useState(null);
  const params = useParams();

  const [dataList, setDataList] = useState([]);
  // 目前所在頁數
  const [currentPage, setCurrentPage] = useState(1);
  // 每頁顯示項目數量
  const itmesPerPage = 20;
  // 總共需要的頁數 
  const totalPages = Math.ceil(dataList.length / itmesPerPage);
  // 計算該頁面最後 item 為第幾個。
  const indexOfLastItem = currentPage * itmesPerPage;
  // 計算該頁面開始 item 為第幾個。
  const indexOfFirstItem = indexOfLastItem - itmesPerPage;
  // 提取該頁面要顯示的資料
  const currentItems = dataList.slice(indexOfFirstItem, indexOfLastItem);

  const { toHHMMSS } = useTimeUtils();

  const ToPercent = (num) => {
    return Number(Math.round(num * 100).toFixed(2));
  };
  const fetchCourses = async () => {
    const searchText = "";
    await coursesApi.getCourses(params.enterpriseId, searchText).then((res) => {
      if (!res.data) return;
      let filterCourses = res.data.filter((p) => p.status !== "UnPubliish");
      setCoursesList(filterCourses);
    });
  };
  const fetchPackageCourses = async () => {
    await examPackageApi
      .getCourses(params.enterpriseId, selectExamPacakge.id)
      .then((res) => {
        if (!res.data) return;
        let filterCourses = res.data.filter((p) => p.status !== "UnPubliish");
        setCoursesList(filterCourses);
      });
  };

  const fetchPackages = async () => {
    await examPackageApi.getExamPackages(params.enterpriseId).then((res) => {
      setExamPackages(res.data);
    });
  };

  const fetchCoursePackages = async () => {
    const searchText = "";
    await coursesApi
      .getCourseContent(params.enterpriseId, selectCourse.id, searchText)
      .then((res) => {
        setExamPackages(res.data);
      });
  };

  const fetchRank = async () => {
    return recordsApi
      .getRanking(params.enterpriseId, selectCourse?.id, selectExamPacakge?.id)
      .then((res) => {
        return res.data;
      });
  };

  const { data: userRankList } = useQuery({
    queryKey: [
      "fetchRank",
      params.enterpriseId,
      selectCourse?.id,
      selectExamPacakge?.id,
    ],
    queryFn: fetchRank,
  });

  const onCourseSelectedChanged = (event) => {
    let selectCourse = coursesList.find((p) => p.id == event.target.value);
    setSelectCourse(selectCourse);
  };

  const onExamPackageSelectedChanged = (event) => {
    let selectExamPacakge = examPackageList.find(
      (p) => p.id == event.target.value
    );
    setSelectExamPacakge(selectExamPacakge);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // 從 <PaginationBar> 取得當前所在頁數
  const handleCurrentPage = (page) => {
    setCurrentPage(page)
  }

  useEffect(() => {
    if (course) return;
    if (!selectExamPacakge) fetchCourses();
    else fetchPackageCourses();
  }, [selectExamPacakge]);

  useEffect(() => {
    if (!selectCourse) {
      fetchPackages();
    } else fetchCoursePackages();
  }, [selectCourse]);

  useEffect(() => {
    if (!course) {
      fetchCourses();
    }
  }, []);

  useEffect(() => {
    if (userRankList) {
      setDataList(userRankList);
    }
  }, [userRankList]);

  return (
    <Grid className={styles.card} container direction="column">
      <Grid item container xs={12} justifyContent={"space-between"}>
        <div>
          <div className={styles.card_title}>{t("RankCard.Card_title")}</div>
          <div className={styles.card_subtitle}></div>
        </div>
        <Grid item container sx={{ width: "auto" }}>
          {coursesList.length > 0 && (
            <Grid item>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <Select
                  value={selectCourse?.id ?? ""}
                  onChange={(e) => onCourseSelectedChanged(e)}
                  displayEmpty
                >
                  <MenuItem value="">{t("RankCard.Select_item_class")}</MenuItem>
                  {coursesList.map((item) => {
                    return (
                      <MenuItem key={`course_${item.id}`} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <Select
                value={selectExamPacakge?.id ?? ""}
                onChange={onExamPackageSelectedChanged}
                displayEmpty
              >
                <MenuItem value="">{t("RankCard.Select_item_course")}</MenuItem>
                {examPackageList.map((item) => {
                  return (
                    <MenuItem key={`package_${item.id}`} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          TabIndicatorProps={{
            style: {
              backgroundColor: "#7000FF",
            },
          }}
        >
          <Tab
            classes={{ root: styles.tab }}
            label={t("RankCard.Tab_LearningTime")}
            value={0}
          />
          <Tab
            classes={{ root: styles.tab }}
            label={t("RankCard.Tab_Mastery")}
            value={1}
          />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <div>
          <table className={styles.table} cellSpacing={0}>
            <thead>
              <tr>
                <th width="40"></th>
                <th>{t("RankCard.LearningTime_table_account")}</th>
                <th>{t("RankCard.LearningTime_table_name")}</th>
                <th>{t("RankCard.LearningTime_table_learningTime")}</th>
              </tr>
            </thead>
            <tbody>
              {currentItems
                ?.sort((a, b) => {
                  return b.traningSecond - a.traningSecond;
                })
                .map((item, index) => {
                  return (
                    <tr key={`tr_${index}`}>
                      <td>{index + 1}</td>
                      <td>{item.enterpriseUserName}</td>
                      <td>{item.fullName}</td>
                      <td>{toHHMMSS(item.traningSecond)}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <div>
          <table className={styles.table} cellSpacing={0}>
            <thead>
              <tr>
                <th width="40"></th>
                <th>{t("RankCard.Mastery_table_account")}</th>
                <th>{t("RankCard.Mastery_table_name")}</th>
                <th>{t("RankCard.Mastery_table_mastery")}</th>
              </tr>
            </thead>
            <tbody>
              {currentItems
                ?.sort((a, b) => {
                  return b.masteryRate - a.masteryRate;
                })
                .map((item, index) => {
                  return (
                    <tr key={`tr_${item.id}`}>
                      <td>{index + 1}</td>
                      <td>{item.enterpriseUserName}</td>
                      <td>{item.fullName}</td>
                      <td>{ToPercent(item.masteryRate)}%</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </TabPanel>
      <Box className={styles.pagination}>
        <Pagination totalPages={totalPages} currentPage={handleCurrentPage} dataLength={dataList.length} />
      </Box>
    </Grid>
  );
};

export default RankCard;
