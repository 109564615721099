import {
  Navigate,
  useLocation,
  Outlet,
  useParams,
  useNavigate,
} from "react-router-dom";
import Topbar from "../../../components/Common/Topbar";
import Sidebar from "../../../components/Common/Sidebar";
import styles from "./index.module.scss";
import {
  mdiDomain,
  mdiAccountOutline,
  mdiSchoolOutline,
  mdiChartLine,
} from "@mdi/js";
import { PermissionLevel } from "../../../constants/PermissionLevel";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const SchoolMainPage = (props) => {
  const { t } = useTranslation();
  const sidebarNavs = [
    {
      id: "enterpriseinfo",
      text: t("MainPage.Title_organization"),
      icon: mdiDomain,
      to: "enterpriseInfo",
      items: [
        {
          id: "enterpriseinfo",
          text: t("MainPage.Organization_item_info"),
          to: "enterpriseInfo",
        },
        {
          id: "overview",
          text: t("MainPage.Organization_item_summary"),
          to: "management/overview",
          level: PermissionLevel.EnterpriseAdmin,
        },
        {
          id: "coursematerials",
          text: t("MainPage.Organization_item_courses"),
          to: "coursematerials",
        },
        {
          id: "enterprises",
          text: t("MainPage.Organization_item_management"),
          to: "enterprises",
          level: PermissionLevel.DealerAdmin,
        },
      ],
    },
    {
      id: "users",
      text: t("MainPage.Title_personnel"),
      icon: mdiAccountOutline,
      to: "management/users",
      level: PermissionLevel.EnterpriseAdmin,
      items: [
        {
          id: "users",
          text: t("MainPage.Personnel_item_management"),
          to: "management/users",
          level: PermissionLevel.EnterpriseAdmin,
        },
      ],
    },
    {
      id: "courses",
      text: t("MainPage.Title_classes"),
      icon: mdiSchoolOutline,
      to: "courses",
      items: [
        {
          id: "courses",
          text: t("MainPage.Classes_item_management"),
          to: "courses",
        },
        {
          id: "courseimport",
          text: t("MainPage.Classes_item_import"),
          to: "import",
        },
      ],
    },
    {
      id: "reports",
      text: t("MainPage.Title_reports"),
      icon: mdiChartLine,
      to: "reports",
      items: [
        {
          id: "reports",
          text: t("MainPage.Reports_item_generate"),
          to: "reports",
        },
      ],
    },
  ];

  const drawerWidth = 240;
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Topbar handleDrawerOpen={handleDrawerToggle} />
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          <Sidebar navs={sidebarNavs} onClose={handleDrawerToggle} />
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          <Sidebar navs={sidebarNavs} />
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
        className={styles.main}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default SchoolMainPage;
