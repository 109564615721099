import { useState, useEffect } from "react";
import styles from "./index.module.scss";
import classnames from "classnames";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Box,
  LinearProgress,
  MenuItem,
  Chip,
  FormControl,
  Grid,
  Popover,
  Select,
  Button,
  Tabs,
  Tab,
  Tooltip,
  CircularProgress,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import recordsApi from "../../../services/school/records";
import coursesApi from "../../../services/school/courses";
import examPackageApi from "../../../services/school/examPackage";
import DonutChart from "../DrawCharts/DonutChart";
import BarChart from "../DrawCharts/BarChart";
import ChartMark from "../DrawCharts/ChartMark";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Icon from "@mdi/react";
import {
  mdiTrendingUp,
  mdiTrendingDown,
  mdiAccountDetailsOutline,
  mdiNoteEditOutline,
  mdiClockOutline,
  mdiAvTimer,
  mdiHelpCircleOutline,
  mdiArrowLeft,
  mdiCalendarMonthOutline,
} from "@mdi/js";
import HistogramChart from "../HistogramChart";
import LineChart from "../LineChart";
import TabPanel from "../TabPanel";
import { useTimeUtils } from "../../../hooks/timeUtils";
import { useTranslation } from "react-i18next";
import zhTW from "date-fns/locale/zh-TW";
import { useQuery } from "@tanstack/react-query";


const MyToolTip = ({ text }) => {
  return (
    <Tooltip
      arrow
      title={<span className={styles.tooltipText}>{text}</span>}
      placement="top-start"
      classes={{
        popper: styles.tooltip_popper,
        tooltip: styles.tooltip,
      }}
    >
      <Icon
        path={mdiHelpCircleOutline}
        size={1}
        className={styles.tooltip_icon}
      />
    </Tooltip>
  );
};

const LearnerAmountChart = ({ dailyRecord, weekRecord, monthRecord, upStartAndEnd, dateRangeRecord }) => {
  const { t } = useTranslation();
  const [period, setPeriod] = useState("daily");
  const [data, setData] = useState([]);
  const [gap, setGap] = useState();
  const [parentState, setParentState] = useState([null, null]);

  const updateParentState = (dateRange) => {
    setParentState(dateRange);
    upStartAndEnd(dateRange);
  };

  const setChartDate = (record) => {
    if (!record) {
      setData([]);
      setGap();
    } else {
      setData(
        record.data.map((p) => {
          return { time: p.time, value: p.usedUserAmount };
        })
      );
      setGap(record.gap);
    }
  };
  useEffect(() => {
    if (period != "daily" || !dailyRecord) return;
    setChartDate(dailyRecord);
  }, [period, dailyRecord]);
  useEffect(() => {
    if (period != "week" || !weekRecord) return;
    setChartDate(weekRecord);
  }, [period, weekRecord]);
  useEffect(() => {
    if (period != "month" || !monthRecord) return;
    setChartDate(monthRecord);
  }, [period, monthRecord]);
  useEffect(() => {
    if (period != "range" || !dateRangeRecord) return;
    setChartDate(dateRangeRecord);
  }, [period, dateRangeRecord]);

  return (
    <div className={styles.card}>
      <Grid container item xs={12} justifyContent={"space-between"}>
        <div>
          <div className={styles.card_title}>
            {t("CourseRecordSummary.LearnerAmountChart_card_title")}
          </div>
          <div className={styles.card_subtitle}></div>
        </div>
        <div className={styles.chart_btns}>
          <Chip
            className={styles.chart_btn}
            label={t("CourseRecordSummary.LearnerAmountChart_label_days")}
            clickable
            color={period == "daily" ? "primary" : "default"}
            variant="contained"
            onClick={() => setPeriod("daily")}
          />
          <Chip
            className={styles.chart_btn}
            label={t("CourseRecordSummary.LearnerAmountChart_label_weeks")}
            clickable
            color={period == "week" ? "primary" : "default"}
            variant="contained"
            onClick={() => setPeriod("week")}
          />
          <Chip
            className={styles.chart_btn}
            label={t("CourseRecordSummary.LearnerAmountChart_label_months")}
            clickable
            color={period == "month" ? "primary" : "default"}
            variant="contained"
            onClick={() => setPeriod("month")}
          />
          <DateRangeSelector
            onRangeSelected={() => setPeriod("range")}
            onStartAndEnd={updateParentState}
          />
        </div>
      </Grid>
      <Grid container item xs={12}>
        <HistogramChart data={data} gap={gap}></HistogramChart>
      </Grid>
    </div>
  );
};

const LearningTimeChart = ({ dailyRecord, weekRecord, monthRecord, upStartAndEnd, dateRangeRecord }) => {
  const { t } = useTranslation();
  const [period, setPeriod] = useState("daily");
  const [data, setData] = useState([]);
  const [gap, setGap] = useState();
  const [parentState, setParentState] = useState([null, null]);


  const updateParentState = (dateRange) => {
    setParentState(dateRange);
    upStartAndEnd(dateRange);
  };

  const setChartDate = (record) => {
    if (!record) {
      setData([]);
      setGap();
    } else {
      setData(
        record.data.map((p) => {
          return { time: p.time, value: p.totalTraningSecond / 3600 };
        })
      );
      setGap(record.gap);
    }
  };
  useEffect(() => {
    if (period != "daily" || !dailyRecord) return;
    setChartDate(dailyRecord);
  }, [period, dailyRecord]);
  useEffect(() => {
    if (period != "week" || !weekRecord) return;
    setChartDate(weekRecord);
  }, [period, weekRecord]);
  useEffect(() => {
    if (period != "month" || !monthRecord) return;
    setChartDate(monthRecord);
  }, [period, monthRecord]);
  useEffect(() => {
    if (period != "range" || !dateRangeRecord) return;
    setChartDate(dateRangeRecord);
  }, [period, dateRangeRecord]);
  return (
    <div className={styles.card}>
      <Grid container item xs={12} justifyContent={"space-between"}>
        <div>
          <div className={styles.card_title}>
            {t("CourseRecordSummary.LearningTimeChart_card_title")}
          </div>
          <div className={styles.card_subtitle}></div>
        </div>
        <div className={styles.chart_btns}>
          <Chip
            className={styles.chart_btn}
            label={t("CourseRecordSummary.LearningTimeChart_label_days")}
            clickable
            color={period == "daily" ? "primary" : "default"}
            variant="contained"
            onClick={() => setPeriod("daily")}
          />
          <Chip
            className={styles.chart_btn}
            label={t("CourseRecordSummary.LearningTimeChart_label_weeks")}
            clickable
            color={period == "week" ? "primary" : "default"}
            variant="contained"
            onClick={() => setPeriod("week")}
          />
          <Chip
            className={styles.chart_btn}
            label={t("CourseRecordSummary.LearningTimeChart_label_months")}
            clickable
            color={period == "month" ? "primary" : "default"}
            variant="contained"
            onClick={() => setPeriod("month")}
          />
          <DateRangeSelector
            onRangeSelected={() => setPeriod("range")}
            onStartAndEnd={updateParentState}
          />
        </div>
      </Grid>
      <Grid container item xs={12}>
        <LineChart data={data} gap={gap}></LineChart>
      </Grid>
    </div>
  );
};

const CircleCard = ({
  title,
  data,
  descript,
  tooltip,
  dsiplayProcess,
  type,
}) => {
  return (
    <div className={styles.card}>
      <Grid container justifyContent="space-between">
        <div className={styles.card_title}>{title}</div>
        <div></div>
        {tooltip && <MyToolTip text={tooltip} />}
      </Grid>
      <Grid container direction="row">
        <Grid item xs={4}>
          <DonutChart data={data} />
        </Grid>
        <Grid
          item
          xs={8}
          container
          direction={"column"}
          paddingLeft={4}
          paddingRight={4}
          paddingTop={2}
          gap={0.5}
        >
          {data.map((item, index) => {
            return (
              <div key={index} className={styles.circle_item}>
                <span
                  className={styles.circle_item_point}
                  style={{ color: `${item.color}` }}
                >
                  ●
                </span>
                {item.name} : {item.display}
              </div>
            );
          })}
          <div className={styles.circle_descript}>{descript}</div>
        </Grid>
      </Grid>
      {/* <DonutChart data={TrainingChart} colors={["#00A3FF", "#4770FF"]} onMarkerMouseover={handlerMarkerMouseOver} onMarkerMouseout={handleTipPopoverClose} value={pieChartValue} />
                {!TrainingChart[0]?.value && <EstablishBtn />}
                <ChartMark data={TrainingArr} />
                <p className={styles.DonutChartDesc}>使用者作答與檢討的時間比例為 {` 1 : ${(TrainingChart[1]?.value / TrainingChart[0]?.value).toFixed(2)} `}, 在整體學習時間中, 較多的時間用於{TrainingChart[1]?.value > TrainingChart[0]?.value ? "檢討" : "作答"}, 另外作答時間約為檢討時間的{(1 / (TrainingChart[1]?.value / TrainingChart[0]?.value)).toFixed(2)}倍</p> */}
    </div>
  );
};

const DateRangeSelector = ({ onRangeSelected, onStartAndEnd }) => {
  const { t } = useTranslation();
  // 日期的範圍 [開始, 結束]
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  // 警告提示
  const showInvalidRangeToast = () => {
    alert(t("RecordSummary.DateRangeSelector_errorAlert"));
  };

  // 開始與結束時間
  const handleRangeSelected = (dateRange) => {
    onStartAndEnd(dateRange);
  };

  // 發生改變時執行
  const onChange = (update) => {
    // 2592000000 milliseconds = 30 days
    const thirtyDay = 2592000000;
    const beginDay = update[0];
    const endDay = update[1];

    if (endDay - beginDay <= thirtyDay) {
      setDateRange(update);
    } else {
      // 顯示警告提示
      showInvalidRangeToast();
    }
    // 若開始時間與結束時間都有值，且兩者差距不超過 30 天。
    if (endDay && beginDay && endDay - beginDay <= thirtyDay) {
      // 更新開始與結束時間
      handleRangeSelected([beginDay, endDay]);
      // 執行 setPeriod("range")
      onRangeSelected();
    }
  };

  return (
    <div className="date-range-selector-container">
      {/*
       showIcon : icon 圖示A
       selectsRange : 是否可以選擇兩個日期
       startDate : 開始時間
       endDate : 結束時間
       onChange : 當選擇的日期發生變化時
       placeholderText : 提示欄位
       dateFormat : 選擇日期後所顯示的值，預設為 yyyy/MM/dd
       locale : 語言 (設定為中文)
       isClearable : 清除按鈕
       */}
      <DatePicker
        className={styles.data_range_selector}
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={onChange}
        placeholderText={t(
          "RecordSummary.DateRangeSelector_placeholderText"
        )}
        dateFormat="MM/dd"
        locale={zhTW}
        isClearable={true}
        customInput={
          <OutlinedInput
            size="small"
            sx={{ minWidth: 50, maxWidth: 175 }}
            startAdornment={
              <InputAdornment position="start">
                <Icon path={mdiCalendarMonthOutline} size={0.8} />
              </InputAdornment>
            }
          />
        }
      />
    </div>
  );
};

const CourseRecordSummary = ({ courseId }) => {
  const { t, i18n } = useTranslation();
  const [summaryInfo, setSummaryInfo] = useState(null);
  const [activityProgress, setActivityProgress] = useState(0);
  const [masterChangeProgress, setMasterChangeProgress] = useState(0);
  const [activityData, setActivityData] = useState([]);
  const [traingTimeData, setTraingTimeData] = useState([]);
  const [dailyRecord, setDailyRecord] = useState();
  const [weekRecord, setWeekRecord] = useState();
  const [monthRecord, setMonthRecord] = useState();
  const params = useParams();
  const { toHHMMSS } = useTimeUtils();
  const [amountChartRangeDay, setamountChartRangeDay] = useState([null, null]);
  const [timeChartRangeDay, setTimeChartRangeDay] = useState([null, null]);

  const fetchSummary = async () => {
    await recordsApi.getSummary(params.enterpriseId, courseId).then((res) => {
      setSummaryInfo(res.data);
    });
  };

  const updateLearnerAmountChartState = (dateRange) => {
    setamountChartRangeDay(dateRange);
  };
  const updateLearningTimeChartState = (dateRange) => {
    setTimeChartRangeDay(dateRange);
  };

  const amountChartBeginDay = amountChartRangeDay[0];
  const amountChartEndDay = amountChartRangeDay[1];

  const timeChartBeginDay = timeChartRangeDay[0];
  const timeChartEndDay = timeChartRangeDay[1];

  const fetchDailyRecord = async () => {
    let now = new Date();
    let currentHourTime = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      now.getHours()
    ).getTime();
    let nextHourTime = currentHourTime + 1 * 60 * 60 * 1000;
    let today = new Date(nextHourTime);
    let yesterday = new Date(nextHourTime - 24 * 60 * 60 * 1000);
    let gap = 2;
    await recordsApi
      .getDailySummary(params.enterpriseId, yesterday, today, gap, courseId)
      .then((res) => {
        setDailyRecord({
          data: res.data,
          gap: gap,
        });
      });
  };

  const fetchWeekRecord = async () => {
    let now = new Date();
    let todayTime = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      23,
      59,
      59
    ).getTime();
    let today = new Date(todayTime);
    let lateWeekDay = new Date(todayTime - 7 * 24 * 60 * 60 * 1000);
    lateWeekDay.setDate(lateWeekDay.getDate() - 7);
    let gap = 24;
    await recordsApi
      .getDailySummary(params.enterpriseId, lateWeekDay, today, gap, courseId)
      .then((res) => {
        setWeekRecord({
          data: res.data,
          gap: gap,
        });
      });
  };

  const fetchDateRangeRecord = async (begin, endDay) => {
    let endDay1 = new Date(
      endDay.getFullYear(),
      endDay.getMonth(),
      endDay.getDate(),
      23,
      59,
      59
    ).getTime();

    let end = new Date(endDay1);

    let gap = 24;

    return await recordsApi
      .getDailySummary(params.enterpriseId, begin, end, gap)
      .then((res) => {
        return {
          data: res.data,
          gap: gap,
        };
      });
  };

  const fetchMonthRecord = async () => {
    let now = new Date();
    let todayTime = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      23,
      59,
      59
    ).getTime();
    let today = new Date(todayTime);
    let lateMonthDay = new Date(todayTime);
    lateMonthDay.setMonth(lateMonthDay.getMonth() - 1);
    let gap = 24;
    await recordsApi
      .getDailySummary(params.enterpriseId, lateMonthDay, today, gap, courseId)
      .then((res) => {
        setMonthRecord({
          data: res.data,
          gap: gap,
        });
      });
  };

  useEffect(() => {
    if (!summaryInfo) return;
    if (summaryInfo.enabledStudentAmount == 0) setActivityProgress(0);
    else {
      let porgress =
        (summaryInfo.activityUserAmount * 100) /
        summaryInfo.enabledStudentAmount;
      setActivityProgress(porgress);
    }

    setMasterChangeProgress(
      summaryInfo.masterAchieveRate - summaryInfo.lastWeekMasterAchieveRate
    );

    const reviewActivityAmonunt =
      summaryInfo.sumActivityAmount - summaryInfo.firstActivityAmount;
    const activityData = [
      {
        name: t("CourseRecordSummary.ActivityData_initial"),
        value: summaryInfo.firstActivityAmount,
        display: t("CourseRecordSummary.FirstActivityAmount", { var: summaryInfo.firstActivityAmount }),
        color: "#ffc700",
      },
      {
        name: t("CourseRecordSummary.ActivityData_review"),
        value: reviewActivityAmonunt,
        display: t("CourseRecordSummary.ReviewActivityAmonunt", { var: reviewActivityAmonunt }),
        color: "#ff7f36",
      },
    ];
    const traingTimeData = [
      {
        name: t("CourseRecordSummary.AnsweringTime_title"),
        value: summaryInfo.sumQuizSecond,
        display: toHHMMSS(summaryInfo.sumQuizSecond),
        color: "#00a3ff",
      },
      {
        name: t("CourseRecordSummary.ReviewTime_title"),
        value: summaryInfo.sumConfirmSecond,
        display: toHHMMSS(summaryInfo.sumConfirmSecond),
        color: "#4770ff",
      },
    ];
    setActivityData(activityData);
    setTraingTimeData(traingTimeData);
  }, [summaryInfo, i18n.language]);
  useEffect(() => {
    fetchSummary();
    fetchDailyRecord();
    fetchWeekRecord();
    fetchMonthRecord();
  }, []);

  const learnerAmountCharRecord = useQuery({
    queryKey: ["fetchDateRangeRecord", amountChartRangeDay],
    queryFn: () => fetchDateRangeRecord(amountChartBeginDay, amountChartEndDay),
  });

  const learningTimeChartRecord = useQuery({
    queryKey: ["fetchDateRangeRecord", timeChartRangeDay],
    queryFn: () => fetchDateRangeRecord(timeChartBeginDay, timeChartEndDay),
  });

  return (
    <>
      <Box>
        <Box className={styles.main_content}>
          <Grid container alignItems="start" direction="row" spacing={2}>
            <Grid item container xs={12}></Grid>
            <Grid item container xs={12} md={6}>
              <CircleCard
                title={t("CourseRecordSummary.TotalInteraction_title")}
                data={activityData}
                //     descript={"使用者初次學習與後續複習的比例為 1 : 0.89, 平均每一道題目會被作答 1.89 次, 其中包含了第 1 次的學習, 加上後續的 0.89 次複習"}
                tooltip={t("CourseRecordSummary.TotalInteraction_tooltip")}
              />
              {/* <div className={styles.chartTitle_container}>
                                <p className={styles.chartTitle}>總題目互動數</p>
                                <Tooltip
                                    arrow
                                    title={<span className={styles.tooltipText}>閱讀指南：<br />
                                        通常圓環中的複習次數會越來越多，表示學習者除了初次作答，後續也都有進行複習。</span>} placement="top-start"
                                    classes={{
                                        popper: styles.tooltip_popper,
                                        tooltip: styles.tooltip
                                    }}
                                >
                                    <Icon path={mdiHelpCircleOutline} size={1} />
                                </Tooltip>
                            </div>

                            <DonutChart data={QuizChart} colors={["#FFC700", "#FF7F36"]} onMarkerMouseover={handlerMarkerMouseOver} onMarkerMouseout={handleTipPopoverClose} value={actPieChartValue} />
                            {!QuizChart[0]?.value && !QuizChart[1]?.value && <EstablishBtn />}
                            <ChartMark data={QuizArr} />
                            <p className={styles.DonutChartDesc}>使用者初次學習與後續複習的比例為 {`1 : ${(QuizChart[1]?.value / QuizChart[0]?.value).toFixed(2)}`}, 平均每一道題目會被作答 {(1 + QuizChart[1]?.value / QuizChart[0]?.value).toFixed(2)} 次, 其中包含了第 1 次的學習, 加上後續的 {(QuizChart[1]?.value / QuizChart[0]?.value).toFixed(2)} 次複習</p> */}
            </Grid>
            <Grid container item xs={12} md={6}>
              <CircleCard
                title={t("CourseRecordSummary.AccumulatedAnsweringTime_title")}
                data={traingTimeData}
                //    descript="使用者作答與檢討的時間比例為 1 : 0.53 , 在整體學習時間中, 較多的時間用於作答, 另外作答時間約為檢討時間的1.89倍"
                tooltip={t("CourseRecordSummary.AccumulatedAnsweringTime_tooltip")}
              />
              {/* <div className={styles.chartTitle_container}>
                                <p className={styles.chartTitle}>累積做題時間</p>
                                <Tooltip
                                    arrow
                                    title={<span className={styles.tooltipText}>
                                        閱讀指南：<br />
                                        如果圓環圖中的作答時間遠大於檢討時間，說明學習者通常沒有在顯示答案後多花精力在檢討上。
                                    </span>} placement="top-start"
                                    classes={{
                                        popper: styles.tooltip_popper,
                                        tooltip: styles.tooltip
                                    }}
                                >
                                    <Icon path={mdiHelpCircleOutline} size={1} />
                                </Tooltip>
                            </div>
                            <DonutChart data={TrainingChart} colors={["#00A3FF", "#4770FF"]} onMarkerMouseover={handlerMarkerMouseOver} onMarkerMouseout={handleTipPopoverClose} value={pieChartValue} />
                            {!TrainingChart[0]?.value && <EstablishBtn />}
                            <ChartMark data={TrainingArr} />
                            <p className={styles.DonutChartDesc}>使用者作答與檢討的時間比例為 {` 1 : ${(TrainingChart[1]?.value / TrainingChart[0]?.value).toFixed(2)} `}, 在整體學習時間中, 較多的時間用於{TrainingChart[1]?.value > TrainingChart[0]?.value ? "檢討" : "作答"}, 另外作答時間約為檢討時間的{(1 / (TrainingChart[1]?.value / TrainingChart[0]?.value)).toFixed(2)}倍</p> */}
            </Grid>

            <Grid container item xs={12} xl={6}>
              <LearnerAmountChart
                dailyRecord={dailyRecord}
                weekRecord={weekRecord}
                monthRecord={monthRecord}
                dateRangeRecord={learnerAmountCharRecord.data}
                upStartAndEnd={updateLearnerAmountChartState}
              />
            </Grid>
            <Grid container item xs={12} xl={6}>
              <LearningTimeChart
                dailyRecord={dailyRecord}
                weekRecord={weekRecord}
                monthRecord={monthRecord}
                dateRangeRecord={learningTimeChartRecord.data}
                upStartAndEnd={updateLearningTimeChartState}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default CourseRecordSummary;
