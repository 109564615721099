import { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import enterpriseApi from '../services/enterprise/enterpriseInfo';
import { setInfo, reset } from '../reducers/enterprise';

const useEnterprise = () => {
    const dispatch = useDispatch();

    const fetchEnterpriseInfo = useCallback(async (enterpriseId) => {
        await enterpriseApi.getInfo(enterpriseId)
            .then(res => {
                dispatch(setInfo({ id: enterpriseId, info: res.data }));
            })
    }, []);


    return {
        fetchEnterpriseInfo
    };
};
export default useEnterprise;