import {
  Navigate,
  useLocation,
  Outlet,
  useParams,
  useNavigate,
} from "react-router-dom";
import Topbar from "../../../components/Common/Topbar";
import Sidebar from "../../../components/Common/Sidebar";
import styles from "./index.module.scss";
import {
  mdiDomain,
  mdiAccountOutline,
  mdiSchoolOutline,
  mdiChartLine,
} from "@mdi/js";
import { PermissionLevel } from "../../../constants/PermissionLevel";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const SchoolMainPage = (props) => {
  const { t } = useTranslation();
  const sidebarNavs = [
    {
      id: "enterpriseinfo",
      text: t("MainPage.Title_organization"),
      icon: mdiDomain,
      to: "enterprises",
      items: [
        {
          id: "enterprises",
          text: t("MainPage.Organization_item_management"),
          to: "enterprises",
          level: PermissionLevel.DealerAdmin,
        },
      ]
    },
  ];

  const drawerWidth = 240;
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Topbar handleDrawerOpen={handleDrawerToggle} />
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          <Sidebar navs={sidebarNavs} onClose={handleDrawerToggle} />
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          <Sidebar navs={sidebarNavs} />
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
        className={styles.main}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default SchoolMainPage;
