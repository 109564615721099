import {
  Navigate,
  useLocation,
  Outlet,
  useParams,
  useNavigate,
} from "react-router-dom";
import Topbar from "../../../components/Enterprise/Topbar";
import Sidebar from "../../../components/Enterprise/Sidebar";
import styles from "./index.module.scss";
import {
  mdiDomain,
  mdiEmailFastOutline,
  mdiBookOpenBlankVariant,
  mdiChartLine,
} from "@mdi/js";
import { PermissionLevel } from "../../../constants/PermissionLevel";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { Container } from "@mui/material";
import { Typography } from "@mui/material";
import useEnterprise from "../../../hooks/enterprise";
const EnterpriseHeader = () => {
  const [enterpriseName, setEnterpriseName] = useState("");
  const enterpriseStore = useSelector(state => state.enterprise);

  useEffect(() => {
    if (!enterpriseStore || !enterpriseStore?.info)
      return;

    setEnterpriseName(enterpriseStore.info.name);
  }, [enterpriseStore?.info]);
  return (
    <div>
      <Typography variant="h6">{enterpriseName}</Typography>
    </div>
  );
};

const EnterpriseMainPage = (props) => {
  const { t } = useTranslation();
  const { enterpriseId } = useParams();
  const sidebarNavs = [
    {
      id: "information",
      text: "機構管理",
      icon: mdiDomain,
      items: [
        {
          id: "overview",
          text: "群組概覽",
          to: "information/overview",
        },
        {
          id: "courses",
          text: "群組管理",
          to: "information/courses",
        },
        {
          id: "users",
          text: "人員管理",
          to: "information/users",
        },
        {
          id: "imports",
          text: "批次匯入",
          to: "information/import",
        },
      ],
    },
    {
      id: "contents",
      text: "內容管理",
      icon: mdiBookOpenBlankVariant,
      items: [
        {
          id: "packages",
          text: "課程清單",
          to: "contents/packages",
          level: PermissionLevel.EnterpriseAdmin,
        },
      ],
    },
    {
      id: "reports",
      text: "報表管理",
      icon: mdiChartLine,
      items: [
        {
          id: "export",
          text: "匯出報表",
          to: "reports/export",
        },
        // {
        //   id: "mastery",
        //   text: "知識點掌握度",
        //   to: "reports/mastery",
        // },
        // {
        //   id: "learningTime",
        //   text: "學習時數",
        //   to: "reports/learningTime",
        // },
        // {
        //   id: "learningStatus",
        //   text: "學習狀態",
        //   to: "reports/learningStatus",
        // },
        // {
        //   id: "group",
        //   text: "群組報表",
        //   to: "reports/group",
        // },
      ],
    },
    {
      id: "notification",
      text: "通知管理",
      icon: mdiEmailFastOutline,
      items: [
        {
          id: "send",
          text: "發送通知",
          to: "notification/send",
        },
        {
          id: "templates",
          text: "管理通知範本",
          to: "notification/templates",
        },
        {
          id: "records",
          text: "通知紀錄",
          to: "notification/records",
        },
      ],
    },
  ];

  const drawerWidth = 145;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { fetchEnterpriseInfo } = useEnterprise();
  const enterpriseStore = useSelector(state => state.enterprise);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };


  useEffect(() => {
    if (enterpriseStore?.id !== enterpriseId) {
      fetchEnterpriseInfo(enterpriseId);
    }
  }, [enterpriseId, enterpriseStore?.id]);


  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >

        <Drawer
          variant="temporary"
          open={drawerOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          <div className={styles.drawerHeader} />
          <Sidebar navs={sidebarNavs} onClose={handleDrawerToggle} />
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          <Sidebar navs={sidebarNavs} />
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
        className={styles.main}
      >
        <Topbar drawerOpen={drawerOpen} handleDrawerOpen={handleDrawerToggle} />
        <Container
          maxWidth={"lg"}
          sx={{ width: '100%' }}>
          <EnterpriseHeader />
          <Outlet />
        </Container>
      </Box>
    </Box>
  );
};

export default EnterpriseMainPage;
