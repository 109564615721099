import { configureStore } from '@reduxjs/toolkit'
import user from './reducers/user'
import auth from './reducers/auth'
import enterprise from './reducers/enterprise'

const store = configureStore({
    reducer: {
        auth: auth,
        user: user,
        enterprise: enterprise
    }
})

export default store